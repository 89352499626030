import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { unwrapData, useApi } from '../composables/useApi'
import { useDogsStore } from './dogs'
import { useUserStore } from './user'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { MealPlanType } from '@/models/MealPlan'
import type { MealPlan } from '@/models/MealPlan'
import type { AgeType, Dog, Gender } from '@/models/Dog'
import { usePlanStep } from '@/steps/plan'
import { useSentry } from '@/composables/useSentry'
import { getDogAge } from '@/models/Dog'
import { useDeliveryDates } from '@/composables/useDeliveryDates'

interface RequestDogData {
  key: number
  name: string
  age: {
    is: AgeType
    years: number
    months: number
  }
  weight: {
    current: number
    adult: number
  }
  activity: { name: string }
  bodyShape: { name: string }
  gender: Gender | undefined
  breed: {
    primary: number
    secondary: number
  }
}

interface RequestData {
  postcode: number
  dogs: RequestDogData[]
}

interface ResponseData {
  plans?: MealPlan[]
  weights?: MealPlanWeight[]
  message?: string
}

export const dogIsValid = (dog: Dog): dog is Required<Dog> => {
  return !!(dog.age.is && dog.activity?.name && dog.bodyShape?.name)
}

const createRequestDogData = (dog: Dog, index: number): RequestDogData => {
  const dogAge = getDogAge(dog)

  return {
    key: index + 1,
    name: dog.name,
    age: {
      is: dog.age.is,
      years: dogAge.years,
      months: dogAge.months,
    },
    weight: {
      current: dog.weight.current,
      adult: dog.weight.adult,
    },
    activity: { name: dog.activity?.name ?? '' },
    bodyShape: { name: dog.bodyShape?.name ?? '' },
    gender: dog.gender,
    breed: {
      primary: dog.breed.primary?.id ?? 0,
      secondary: dog.breed.secondary?.id ?? 0,
    },
  }
}

export const MealPriceOptions = ['meal', 'day', 'week'] as const

export type MealPriceOption = (typeof MealPriceOptions)[number]

export const useMealPlansStore = defineStore('mealPlans', () => {
  const mealPlans = ref<MealPlan[]>([])
  const mealWeights = ref<MealPlanWeight[]>([])
  const api = useApi()
  const dogsStore = useDogsStore()
  const userStore = useUserStore()
  const deliveryDates = useDeliveryDates()

  // Create a map of meal plans by their type for easier access
  const mealPlansByType = computed<Map<MealPlanType, MealPlan>>(() => {
    const map = new Map<MealPlanType, MealPlan>()

    for (const plan of mealPlans.value) {
      map.set(plan.type, plan)
    }

    return map
  })

  const loaded = computed(() => {
    return mealPlans.value.length
  })

  const getPostcode = (): string => {
    return userStore.user.postcode
  }

  const loadMeals = async (): Promise<{ mealPlans: MealPlan[]; mealWeights: MealPlanWeight[] }> => {
    const dogs = dogsStore.getDogs()

    // Ensure that all of the dogs have the data that the API requires
    if (!dogs.every(dogIsValid)) {
      useSentry().error('Invalid dog data')
      return {
        mealPlans: [],
        mealWeights: [],
      }
    }

    const postcode = getPostcode()
    const requestData: RequestData = {
      postcode: parseInt(postcode),
      dogs: dogs.map(createRequestDogData),
    }

    mealPlans.value = []
    mealWeights.value = []

    try {
      const { data } = await api.post<ResponseData>('products/mealplans', requestData, unwrapData)

      mealPlans.value = data.plans ?? []
      mealWeights.value = data.weights ?? []

      if (!data.plans?.length) {
        throw new Error(data.message)
      }
    } catch (err) {
      useSentry().error(err)
    }

    return {
      mealPlans: mealPlans.value,
      mealWeights: mealWeights.value,
    }
  }

  const getMealPlansByType = (...types: MealPlanType[]): MealPlan[] => {
    const map = mealPlansByType.value

    return types.map((type) => map.get(type)).filter(Boolean) as MealPlan[]
  }

  const getMealPlanByType = (type: MealPlanType): MealPlan | undefined => {
    return mealPlansByType.value.get(type)
  }

  const selectedPlan = computed<MealPlan | undefined>(() => {
    return mealPlans.value.find((plan) => {
      const selectedPlanType = usePlanStep().data.plan.type

      return plan.type === selectedPlanType
    })
  })

  dogsStore.on('removed', (dogIndex: number) => {
    mealWeights.value.splice(dogIndex, 1)
    loadMeals()
  })

  const fullPlanFrequency = computed<number | undefined>(() => {
    const fullPlan = getMealPlanByType(MealPlanType.Full)

    return fullPlan?.frequency ?? undefined
  })

  const getStarterPlanDuration = async (): Promise<2 | 1> => {
    const TWO_WEEK_DURATION = 2 as const
    const ONE_WEEK_DURATION = 1 as const
    const MIN_DELIVERED_DAYS_FOR_ONE_WEEK_STARTER = 2 as const

    // If the full plan frequency is not set, use the default of 2
    if (fullPlanFrequency.value === undefined) {
      return TWO_WEEK_DURATION
    }

    if (fullPlanFrequency.value > ONE_WEEK_DURATION) {
      return TWO_WEEK_DURATION
    }

    const postcode = getPostcode()

    await deliveryDates.loadDeliveryDates(postcode)

    const days = deliveryDates.getMinDaysBetweenOrders()

    if (days < MIN_DELIVERED_DAYS_FOR_ONE_WEEK_STARTER) {
      return TWO_WEEK_DURATION
    }

    return ONE_WEEK_DURATION
  }

  // For testing only
  const setData = (plans: MealPlan[], weights: MealPlanWeight[]): void => {
    mealPlans.value = plans
    mealWeights.value = weights
  }

  return {
    loaded,
    selectedPlan,
    mealPlans,
    mealWeights,
    mealPlansByType,
    getStarterPlanDuration,
    getMealPlanByType,
    getMealPlansByType,
    loadMeals,
    setData,
  }
})

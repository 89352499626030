<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import { computed } from 'vue'
import PlusIcon from '../../icons/PlusIcon.vue'
import MinusIcon from '../../icons/MinusIcon.vue'
import type { Product } from '@/models/Product'
import { formatMoney } from '@/utils/formatMoney'
import { useProductPrice } from '@/composables/useProductPrice'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  product: Product
  quantity: number
  disabled?: boolean
  multiple?: boolean
}>()

const emits = defineEmits<{
  add: [product: Product]
  remove: [product: Product]
}>()

const price = computed(() => {
  return props.product.price
})

const discounted = computed(() => {
  if (useCoupons().dollarDiscountCouponAttempt.value) {
    return false
  }

  return useProductPrice().isProductDiscounted('treats')
})

const discountedPrice = computed(() => {
  return useProductPrice().getDiscountedPrice('treats', price.value)
})
</script>

<template>
  <div class="treat-card-variant tw-flex tw-justify-between tw-items-center tw-py-4 last:tw-pb-0">
    <div class="tw-space-y-1">
      <div class="tw-text-dark tw-font-semibold">
        {{ product.skuSize }}
      </div>
      <div v-if="multiple" class="tw-font-light">{{ product.customerSubDisplayName }}</div>
      <div class="tw-text-success tw-font-semibold">
        <s v-if="discounted" class="tw-opacity-50">{{ formatMoney(price) }}</s>
        &nbsp;{{ formatMoney(discountedPrice) }}
      </div>
    </div>
    <div class="tw-flex tw-space-x-1">
      <LykaButton
        class="treat-card-variant-decrement"
        outline
        square
        variant="success"
        size="xs"
        aria-label="Remove treat"
        :disabled="quantity === 0"
        @click="emits('remove', product)"
      >
        <MinusIcon />
      </LykaButton>
      <input
        readonly
        tabindex="-1"
        :value="quantity"
        size="2"
        class="tw-text-center tw-outline-none tw-rounded-full tw-w-10 tw-bg-white"
      />
      <LykaButton
        class="treat-card-variant-increment"
        outline
        square
        variant="success"
        size="xs"
        aria-label="Add treat"
        :disabled="disabled"
        @click="emits('add', product)"
      >
        <PlusIcon />
      </LykaButton>
    </div>
  </div>
</template>

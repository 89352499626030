<script setup lang="ts">
import { computed, ref } from 'vue'
import TreatsTreatCardModal from '../treats/TreatsTreatCardModal.vue'
import image from '@/assets/images/steps/recipes/free-meal-topper.webp'
import type { Treat } from '@/models/Treat'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  treat: Treat
}>()

const open = ref(false)

const showProductDetails = (): void => {
  open.value = true
}

const hideProductDetails = (): void => {
  open.value = false
}

const originalPrice = computed<number | undefined>(() => {
  return props.treat.products[0]?.price
})
</script>

<template>
  <div>
    <div class="tw-rounded-xl tw-p-5 tw-bg-white tw-space-y-5 tw-max-w-md tw-w-full tw-text-sm tw-mx-auto tw-shadow-md">
      <div class="tw-text-green tw-flex tw-gap-2 tw-items-center tw-text-base">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
          <g>
            <circle cx="8.94" cy="8.377" r="7.999" fill="#fff" />
            <path
              fill="#10B193"
              d="M8.94 16.376a8 8 0 1 0 0-15.998 8 8 0 0 0 0 15.998Zm3.531-9.467-4 4a.747.747 0 0 1-1.058 0l-2-2a.75.75 0 0 1 1.06-1.06L7.94 9.318l3.468-3.472a.75.75 0 0 1 1.06 1.06l.002.003Z"
            />
          </g>
        </svg>

        Free gift added to your order
      </div>

      <div class="tw-flex tw-gap-4 tw-items-center">
        <div>
          <img
            :src="image"
            alt="The fussiness topper package"
            class="tw-rounded-lg tw-w-16 tw-h-16 tw-object-cover tw-aspect-square"
          />
        </div>

        <div class="tw-space-y-0.5">
          <h4 class="tw-font-bold">Beef &amp; Parmesan Meal Topper</h4>
          <div>
            <s v-if="originalPrice" class="tw-text-dark/50 tw-inline-block">{{ formatMoney(originalPrice) }}</s
            >&nbsp;<span class="tw-text-green tw-font-bold">FREE</span>
          </div>
          <button type="button" class="tw-underline" @click="showProductDetails">View product details</button>
        </div>
      </div>

      <TreatsTreatCardModal :treat="treat" :open="open" @close="hideProductDetails" />
    </div>
  </div>
</template>

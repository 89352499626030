export interface MealPlanWeight {
  key: number
  name: string
  /**
   * The PUPPY grams per day
   */
  servingWeight: number
  /**
   * The ADULT grams per day
   */
  planWeight: number
}

const KILO_CALORIES_PER_GRAM = 1.3

export const caloriesFromGrams = (grams: number): number => {
  return grams * KILO_CALORIES_PER_GRAM
}

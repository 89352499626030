<script setup lang="ts">
import { LykaButton, useBreakpoints } from '@lyka/ui'
import { computed } from 'vue'
import type { PostCheckoutCallToAction, PostCheckoutClick } from '@/events/checkoutCompleted'
import env from '@/env'

const props = defineProps<PostCheckoutCallToAction>()
const emits = defineEmits<{
  (e: 'click', value: PostCheckoutClick): void
}>()

const imageUrl = computed(() => {
  const url = new URL(`/src/assets/images/${props.imageFilename}`, import.meta.url)

  return url.toString()
})

const onClick = (): void => {
  const redirectionUrl = props.internalNavigation ? `${env.VITE_LYKA_URL}/${props.path}` : props.path

  emits('click', {
    cta: props.buttonText.toLowerCase(),
    path: `/${props.path}`,
    url: redirectionUrl,
  } as PostCheckoutClick)
}

const breakpoints = useBreakpoints()
const isMobile = computed(() => !breakpoints.value.md)
</script>

<template>
  <div class="tw-bg-cream tw-rounded-xl tw-text-alt" :class="{ 'tw-py-3 tw-px-2': isMobile }">
    <!-- Mobile -->
    <div v-if="isMobile" class="tw-flex tw-flex-col tw-text-center tw-justify-between tw-h-full tw-space-y-2">
      <img :src="imageUrl" :alt="imageAltText" class="tw-max-h-32 tw-w-auto tw-mx-auto" />
      <p class="tw-bold-success tw-mb-1">{{ description }}</p>
      <LykaButton block-at="lg" :variant="buttonVariant" @click="onClick">{{ buttonText }}</LykaButton>
    </div>

    <!-- Desktop -->
    <div v-else class="tw-flex tw-justify-between">
      <div class="tw-flex tw-flex-col tw-p-6 tw-gap-6 tw-z-10 xl:tw-w-8/12 lg:tw-w-7/12 tw-w-1/2">
        <p class="tw-bold-success">{{ description }}</p>
        <div class="tw-mr-auto">
          <LykaButton :variant="buttonVariant" @click="onClick">{{ buttonText }}</LykaButton>
        </div>
      </div>

      <div class="xl:tw-w-3/12 lg:tw-w-5/12 tw-w-1/2 tw-relative">
        <div
          class="tw-bg-contain tw-bg-no-repeat tw-bg-right tw-h-full tw-w-[300px] tw-absolute tw-right-0"
          :style="{ backgroundImage: `url(${imageUrl})` }"
        ></div>
      </div>
    </div>
  </div>
</template>

import camelcaseKeys from 'camelcase-keys'
import { useAuthentication } from './useAuthentication'
import env from '@/env'

type Transformer = (data: object) => object

const FETCH_DEFAULTS: Partial<RequestInit> = {
  mode: 'cors',
}

const HEADER_DEFAULTS: HeadersInit = {
  accept: 'application/json',
}

const apiURL = (path: string): URL => {
  return new URL(path, env.VITE_LYKA_API_URL)
}

export const unwrapData = (object: Record<string, any>): object => {
  if (object.data) {
    return object.data
  }

  return object
}

const transform = (object: Record<string, any>, transformer?: Transformer): object => {
  const camelcased = camelcaseKeys(object, { deep: true })

  return transformer ? transformer(camelcased) : camelcased
}

interface ApiOptions {
  authenticate?: boolean
}

interface ApiResult<T = any> {
  status: number
  data: T
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useApi = (options?: ApiOptions) => {
  if (!env.VITE_LYKA_API_URL) {
    throw new Error('VITE_LYKA_API_URL is not defined')
  }

  const { token } = useAuthentication()

  const getRequestHeaders = (method: 'GET' | 'POST' = 'GET'): HeadersInit => {
    const headers: HeadersInit = {
      ...HEADER_DEFAULTS,
    }

    if (options?.authenticate && token.value) {
      headers.authorization = `Bearer ${token.value.access_token}`
    }

    if (method === 'POST') {
      headers['content-type'] = 'application/json'
    }

    return headers
  }

  const get = async <Data = object>(
    path: string,
    params?: Record<string, string | number>,
    transformer?: Transformer,
  ): Promise<ApiResult<Data>> => {
    if (options?.authenticate && !token.value) {
      throw new Error('User is not authenticated')
    }

    const url = apiURL(path)

    if (params) {
      for (const [k, v] of Object.entries(params)) {
        url.searchParams.append(k, v.toString())
      }
    }

    const response = await fetch(url.toString(), {
      ...FETCH_DEFAULTS,
      headers: getRequestHeaders(),
    })

    const { status } = response
    const data = await response.json()

    return {
      data: transform(data, transformer) as Data,
      status,
    }
  }

  const post = async <Data = object, Payload = object>(
    path: string,
    payload: Payload,
    transformer?: Transformer,
  ): Promise<ApiResult<Data>> => {
    if (options?.authenticate && !token.value) {
      throw new Error('User is not authenticated')
    }

    const url = apiURL(path)

    const response = await fetch(url.toString(), {
      ...FETCH_DEFAULTS,
      method: 'POST',
      headers: getRequestHeaders('POST'),
      body: JSON.stringify(payload),
    })

    const { status } = response

    const data = await response.json()

    return {
      data: transform(data, transformer) as Data,
      status,
    }
  }

  return {
    get,
    post,
  }
}

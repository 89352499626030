import { computed } from 'vue'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { useDogsStore } from '@/stores/dogs'
import { useTreatsStore } from '@/stores/treats'
import type { Treat } from '@/models/Treat'

const FUSSINESS_TOPPER_PRODUCT_NAME = 'fussiness_topper' as const
const FUSSINESS_TOPPER_FREE_PRODUCT_NAME = 'fussiness_topper_pouch_75g_free' as const

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFreeFussyMealTopper = () => {
  const experiments = useExperiments()
  const dogs = useDogsStore().getDogs()
  const treatsStore = useTreatsStore()

  const originalProduct = computed<Treat | undefined>(() => {
    return treatsStore.findTreatByName(FUSSINESS_TOPPER_PRODUCT_NAME)
  })

  const freeProduct = computed<Treat | undefined>(() => {
    return treatsStore.findTreatByName(FUSSINESS_TOPPER_FREE_PRODUCT_NAME)
  })

  const freeProductId = computed<number | undefined>(() => {
    return freeProduct.value?.products?.[0]?.id
  })

  const fussyDogs = computed(() => {
    return dogs.filter((dog) => {
      const isFussy = dog.fusinessLevel?.name === 'fussy'
      const isAllergicToBeef = dog.allergies.some((allergy) => allergy.name === 'beef')

      return !isAllergicToBeef && isFussy
    })
  })

  const fussyDogNames = computed(() => {
    return fussyDogs.value.map((dog) => dog.name)
  })

  const enabled = computed<boolean>(() => {
    const isExperimentEnabled = experiments.inExperiment('known-bab-free-topper')
    const productExists = !!(originalProduct.value && freeProduct.value)
    const hasFussyDog = fussyDogs.value.length > 0

    return productExists && hasFussyDog && isExperimentEnabled
  })

  return {
    originalProduct,
    freeProduct,
    freeProductId,
    fussyDogNames,
    enabled,
  }
}

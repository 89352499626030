<script setup lang="ts">
import { computed } from 'vue'
import { MealPlanTypeTrials } from '../../../models/MealPlan'
import CheckoutProductGamePlan from './products/CheckoutProductGamePlan.vue'
import CheckoutProductHypoallergenicProGuide from './products/CheckoutProductHypoallergenicProGuide.vue'
import CheckoutProductGoToGutHelper from './products/CheckoutProductGoToGutHelper.vue'
import CheckoutProductTrialGuide from './products/CheckoutProductTrialGuide.vue'
import CheckoutProductFreeFussyMealTopper from './products/CheckoutProductFreeFussyMealTopper.vue'
import { isTherapeuticRecipe } from '@/models/Recipe'
import { useRecipesStore } from '@/stores/recipes'
import { type MealPlan } from '@/models/MealPlan'
import { useFreeFussyMealTopper } from '@/composables/useFreeFussyMealTopper'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const includesHypoProGuide = computed(() => {
  return useRecipesStore().selectedRecipes.every(isTherapeuticRecipe)
})

const includesTrialGuide = computed(() => {
  return MealPlanTypeTrials.includes(props.mealPlan.type)
})

const includesGoToGutHelper = computed(() => {
  return !MealPlanTypeTrials.includes(props.mealPlan.type)
})

const includesGamePlan = computed(() => {
  return !includesTrialGuide.value && !includesHypoProGuide.value
})

const includesFreeFussyMealTopper = useFreeFussyMealTopper().enabled
</script>

<template>
  <div class="tw-flex tw-flex-col tw-divide-y tw-divide-mint-green">
    <CheckoutProductGoToGutHelper v-if="includesGoToGutHelper" />

    <CheckoutProductFreeFussyMealTopper v-if="includesFreeFussyMealTopper" />

    <CheckoutProductHypoallergenicProGuide v-if="includesHypoProGuide" />
    <CheckoutProductGamePlan v-if="includesGamePlan" />

    <CheckoutProductTrialGuide v-if="includesTrialGuide" />
  </div>
</template>

q
<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl, pluralize } from '@lyka/utils'
import LykaButton from '@lyka/ui/components/LykaButton.vue'
import { isTrialPlanType } from '../../../models/MealPlan'
import { type MealPlan, MealPlanType, getMealPlanName } from '@/models/MealPlan'
import { StepName, useStepsStore } from '@/stores/steps'
import { useProductPrice } from '@/composables/useProductPrice'
import { formatMoney } from '@/utils/formatMoney'
import { useDogsStore } from '@/stores/dogs'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const stepsStore = useStepsStore()
const { getDiscountedPrice } = useProductPrice()
const dogsStore = useDogsStore()

const hasMultipleDogs = computed(() => {
  return dogsStore.multipleDogs
})

const prettyDogNames = computed<string>(() => {
  if (hasMultipleDogs.value) {
    return dogsStore.dogNames
      .map((name, idx) => {
        if (idx < dogsStore.dogNames.length - 1) {
          return `${name} & `
        }
        return `${name}'s`
      })
      .join('')
  }
  return `${dogsStore.dogNames[0]}'s`
})

const mealPlanName = computed(() => getMealPlanName(props.mealPlan.type))

const mealPlanDescription = computed(() => {
  if (isTrialPlanType(props.mealPlan.type)) {
    return mealPlanName.value
  }

  return `${mealPlanName.value}, ${props.mealPlan.frequency} ${pluralize(props.mealPlan.frequency, 'week')} of food`
})

const planImageURL = computed(() => {
  const mealPlanImage = isTrialPlanType(props.mealPlan.type) ? MealPlanType.Trial : props.mealPlan.type

  return assetUrl(`images/steps/plans/${mealPlanImage}.webp`)
})

const discountedTotalPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice, props.mealPlan.type)
})

const discountedDailyPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.priceDaily, props.mealPlan.type)
})

const isTrialPlan = computed(() => {
  return props.mealPlan.type === MealPlanType.Trial
})

const planName = computed(() => {
  const planDescriptor = isTrialPlan.value ? 'Order' : 'Plan'

  return `${prettyDogNames.value} ${planDescriptor}`
})

const displayDailyPrice = computed(() => {
  return !isTrialPlan.value
})
</script>

<template>
  <div class="tw-flex tw-gap-x-4 tw-mb-5">
    <div
      class="tw-rounded-2xl tw-aspect-square tw-flex tw-items-center tw-justify-center tw-w-16 tw-relative tw-shrink-0"
    >
      <img :src="planImageURL" alt="An image of your dog's meal plan" class="tw-object-cover tw-aspect-square" />
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1 tw-w-full">
      <div class="tw-flex tw-justify-between tw-items-start">
        <span>{{ planName }}</span>

        <LykaButton
          type="button"
          variant="success"
          text-link
          class="tw-ml-2"
          @click="stepsStore.goToStep(StepName.Plan)"
        >
          Edit
        </LykaButton>
      </div>
      <span class="tw-text-sm tw-font-light">
        {{ mealPlanDescription }}
      </span>
      <span class="tw-text-sm tw-font-light">
        <span id="checkout-price-plan">{{ formatMoney(discountedTotalPrice) }}</span>

        <span v-if="displayDailyPrice"> at {{ formatMoney(discountedDailyPrice) }}/day</span>
      </span>
    </div>
  </div>
</template>

q
<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl, pluralize } from '@lyka/utils'
import LykaButton from '@lyka/ui/components/LykaButton.vue'
import { type MealPlan, MealPlanType, getMealPlanName, isTrialPlanType } from '@/models/MealPlan'
import { StepName, useStepsStore } from '@/stores/steps'
import { useCoupons } from '@/composables/useCoupons'
import { useProductPrice } from '@/composables/useProductPrice'
import { discountedPrice } from '@/utils/discountedPrice'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const stepsStore = useStepsStore()
const productPrice = useProductPrice()

const mealPlanName = computed<string>(() => getMealPlanName(props.mealPlan.type))

const planImageURL = computed<string>(() => {
  const mealPlanImage = isTrialPlanType(props.mealPlan.type) ? MealPlanType.Trial : props.mealPlan.type

  return assetUrl(`images/steps/plans/${mealPlanImage}.webp`)
})

const totalPrice = computed<number>(() => {
  return props.mealPlan.totalPrice
})

const discount = computed<number | undefined>(() => {
  const isMealTypeDiscounted = productPrice.isMealTypeDiscounted(props.mealPlan.type)

  if (isMealTypeDiscounted === false) {
    return undefined
  }

  return useCoupons().getBoxDiscount(2)
})

const discountedTotalPrice = computed<number>(() => {
  if (discount.value) {
    return discountedPrice(props.mealPlan.totalPrice, discount.value, 'percentage')
  }

  return totalPrice.value
})

const dailyPrice = computed<number>(() => {
  return props.mealPlan.priceDaily
})

const discountedDailyPrice = computed<number>(() => {
  if (discount.value) {
    return discountedPrice(dailyPrice.value, discount.value, 'percentage')
  }

  return dailyPrice.value
})

const ONE_WEEK_DAYS = 7

const daysOfMeals = computed<number>(() => {
  return props.mealPlan.frequency * ONE_WEEK_DAYS
})
</script>

<template>
  <div>
    <div class="tw-flex tw-gap-x-4 tw-mb-6 tw-items-start">
      <div
        class="tw-rounded-2xl tw-aspect-square tw-flex tw-items-center tw-justify-center tw-w-16 tw-relative tw-shrink-0"
      >
        <img :src="planImageURL" alt="An image of your dog's meal plan" class="tw-object-cover tw-aspect-square" />
      </div>

      <div>
        <div class="tw-flex tw-flex-col tw-space-y-1.5 tw-w-full tw-text-sm tw-font-light">
          <div>{{ mealPlanName }}</div>

          <div class="tw-font-bold">{{ daysOfMeals }} days of food</div>

          <div>
            <div>Delivered every {{ mealPlan.frequency }} {{ pluralize(mealPlan.frequency, 'week') }}</div>
          </div>

          <span>
            <span v-if="totalPrice">
              <s v-if="discount">{{ formatMoney(totalPrice) }}</s
              >&nbsp;<strong>{{ formatMoney(discountedTotalPrice) }}</strong> at </span
            >&nbsp;<span v-if="dailyPrice">{{ formatMoney(discountedDailyPrice) }}/day</span>
          </span>
        </div>
      </div>

      <LykaButton
        type="button"
        variant="success"
        text-link
        class="tw-ml-auto"
        @click="stepsStore.goToStep(StepName.Plan)"
      >
        Edit
      </LykaButton>
    </div>

    <div v-if="discount" class="tw-text-green tw-font-bold">You’ve got {{ discount }}% off your second box!</div>
  </div>
</template>

<script setup lang="ts">
import {
  LYKA_PHONE_NUMBER,
  LYKA_SUPPORT_EMAIL,
  LYKA_SUPPORT_HOURS_TIMEZONE,
  LYKA_SUPPORT_HOURS_WEEKDAY,
  LYKA_SUPPORT_HOURS_WEEKEND,
} from '@lyka/constants'
import { LykaButton } from '@lyka/ui'
import LykaLogo from '@lyka/ui/components/LykaLogo.vue'
import { computed, onMounted } from 'vue'
import CheckoutCompletedExperimentTrial from './experiments/CheckoutCompletedExperimentTrial.vue'
import CheckoutCompletedExperimentNext from './experiments/CheckoutCompletedExperimentNext.vue'
import CheckoutCompletedSection from './experiments/CheckoutCompletedSection.vue'
import CheckoutCompletedMap from './experiments/CheckoutCompletedMap.vue'
import CheckoutCompletedExperimentStarter from './experiments/CheckoutCompletedExperimentStarter.vue'
import TickCircleIcon from '@/components/icons/TickCircleIcon.vue'
import { formatTime } from '@/utils/formatTime'
import { isStarterPlanType, isTrialPlanType } from '@/models/MealPlan'
import { usePlanStep } from '@/steps/plan'
import { useCheckoutStep } from '@/steps/checkout'
import { formatDate } from '@/utils/formatDate'
import { formatAddress } from '@/models/Address'
import { useDeliveryDates } from '@/composables/useDeliveryDates'

const phoneNumberLink = `tel:${LYKA_PHONE_NUMBER}`
const emailLink = `mailto:${LYKA_SUPPORT_EMAIL}`

const { loadDeliveryDates, getTimeForSlot, getSlotById } = useDeliveryDates()

const selectedPlanType = usePlanStep().data.plan.type

const address = computed<string>(() => {
  const address = useCheckoutStep().data.address

  return formatAddress(address)
})

const isStarterPlan = computed<boolean>(() => {
  if (selectedPlanType) {
    return isStarterPlanType(selectedPlanType)
  }

  return false
})

const isTrialPlan = computed<boolean>(() => {
  if (selectedPlanType) {
    return isTrialPlanType(selectedPlanType)
  }

  return false
})

const deliveryDate = computed<string | undefined>(() => {
  const selectedDeliveryDate = useCheckoutStep().data.delivery.date

  if (!selectedDeliveryDate) {
    return undefined
  }

  return formatDate(selectedDeliveryDate)
})

const deliveryTime = computed<string | undefined>(() => {
  const selectedDeliverySlotId = useCheckoutStep().data.delivery.slot

  if (!selectedDeliverySlotId) {
    return undefined
  }

  const slot = getSlotById(selectedDeliverySlotId)

  if (!slot) {
    return ''
  }

  return getTimeForSlot(slot)
})

onMounted(() => {
  const postcode = useCheckoutStep().data.address.postcode

  loadDeliveryDates(postcode)
})
</script>

<template>
  <div class="md:tw-grid-cols-2 md:tw-grid tw-gap-x-10 xl:tw-gap-x-24 checkout-completed">
    <CheckoutCompletedSection area="header">
      <div class="tw-w-16 md:tw-w-20">
        <LykaLogo />
      </div>
    </CheckoutCompletedSection>

    <CheckoutCompletedMap :address="address" />

    <CheckoutCompletedSection area="confirmation" class="tw-space-y-4">
      <h3 class="tw-text-green tw-uppercase tw-flex tw-items-center tw-text-sm tw-font-semibold tw-gap-2">
        <span class="tw-text-xl"><TickCircleIcon /></span>
        <span class="tw-leading-none">Order confirmed</span>
      </h3>
      <h2 class="tw-h2">Cue tail wags!</h2>

      <p>
        We're getting your order ready to be delivered on:
        <strong>{{ deliveryDate ?? '' }}, {{ deliveryTime ?? '' }}</strong
        >.
      </p>

      <p class="tw-text-sm">We’ll text you a tracking link when it’s on the way.</p>
    </CheckoutCompletedSection>

    <div
      class="tw-bg-mint-green tw-p-6 md:tw-pt-24 tw-flex md:tw-pl-10 xl:tw-pl-24 tw-items-start tw-self-stretch tw-h-full tw-w-full md:tw-min-h-screen checkout-completed-aside"
    >
      <CheckoutCompletedExperimentStarter v-if="isStarterPlan" :meal-plan-type="selectedPlanType" />
      <CheckoutCompletedExperimentTrial v-else-if="isTrialPlan" />
      <CheckoutCompletedExperimentNext v-else />
    </div>

    <CheckoutCompletedSection area="cta" class="md:tw-pt-0 md:tw-pb-10">
      <div class="tw-w-full md:tw-w-min">
        <LykaButton href="/membership/dashboard" variant="primary" block>Continue to account</LykaButton>
      </div>
    </CheckoutCompletedSection>

    <CheckoutCompletedSection area="support" class="tw-space-y-3 tw-border-t tw-border-dashed tw-border-mint-green">
      <h4 class="tw-font-bold tw-text-lg">Need help?</h4>
      <p>
        Our customer care team is here for you.
        <strong>Mon-Fri {{ LYKA_SUPPORT_HOURS_WEEKDAY.map(formatTime).join('-') }}</strong> and
        <strong>Sat-Sun {{ LYKA_SUPPORT_HOURS_WEEKEND.map(formatTime).join('-') }}</strong>
        {{ LYKA_SUPPORT_HOURS_TIMEZONE }}
      </p>
      <p class="tw-flex tw-items-center tw-gap-2">
        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.375 1.125a2.25 2.25 0 0 0-2.25 2.25v1.688c0 6.523 5.289 11.812 11.813 11.812h1.687a2.25 2.25 0 0 0 2.25-2.25v-1.029c0-.645-.44-1.207-1.065-1.364l-3.318-.83a1.406 1.406 0 0 0-1.466.521l-.727.97c-.076.101-.186.123-.264.095a8.464 8.464 0 0 1-5.023-5.023c-.028-.077-.006-.188.095-.264l.97-.727c.452-.34.658-.918.52-1.466L5.768 2.19a1.406 1.406 0 0 0-1.364-1.065H3.375ZM2.25 3.375c0-.621.504-1.125 1.125-1.125h1.029c.129 0 .241.088.273.213l.829 3.317a.281.281 0 0 1-.104.294l-.97.727c-.463.347-.69.967-.476 1.55a9.589 9.589 0 0 0 5.692 5.693 1.359 1.359 0 0 0 1.55-.476l.728-.97a.281.281 0 0 1 .294-.104l3.317.83a.281.281 0 0 1 .213.272v1.029c0 .621-.504 1.125-1.125 1.125h-1.688C7.035 15.75 2.25 10.965 2.25 5.062V3.375Z"
            fill="#005648"
          />
        </svg>
        <a :href="phoneNumberLink" class="tw-underline">{{ LYKA_PHONE_NUMBER }}</a>
      </p>
      <p class="tw-flex tw-items-center tw-gap-2">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.375 2.8125C2.13236 2.8125 1.125 3.81986 1.125 5.0625V12.9375C1.125 14.1801 2.13236 15.1875 3.375 15.1875H14.625C15.8676 15.1875 16.875 14.1801 16.875 12.9375V5.0625C16.875 3.81986 15.8676 2.8125 14.625 2.8125H3.375ZM2.25 5.24453V5.0625C2.25 4.44118 2.75368 3.9375 3.375 3.9375H14.625C15.2463 3.9375 15.75 4.44118 15.75 5.0625V5.24453C15.75 5.6352 15.5473 5.9979 15.2146 6.20265L9.58961 9.66419C9.22803 9.8867 8.77197 9.8867 8.41039 9.66419L2.78539 6.20265C2.45267 5.9979 2.25 5.6352 2.25 5.24453ZM10.1792 10.6223L15.75 7.19413V12.9375C15.75 13.5588 15.2463 14.0625 14.625 14.0625H3.375C2.75368 14.0625 2.25 13.5588 2.25 12.9375V7.19413L7.82078 10.6223C8.54395 11.0673 9.45605 11.0673 10.1792 10.6223Z"
            fill="#005648"
          />
        </svg>

        <a :href="emailLink" class="tw-underline">{{ LYKA_SUPPORT_EMAIL }}</a>
      </p>
    </CheckoutCompletedSection>
  </div>
</template>

<style scoped lang="postcss">
.checkout-completed {
  align-items: start;

  @apply tw-justify-items-center md:tw-justify-items-end;
  grid-template-areas: 'header aside' 'map aside' 'confirmation aside' 'cta aside' 'support aside' '. aside';
}

.checkout-completed-aside {
  grid-area: aside;

  @apply tw-justify-center md:tw-justify-start;
}
</style>

import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { computed } from 'vue'
import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { useMealPlansStore } from '@/stores/mealPlans'
import { RecipeName } from '@/models/Recipe'
import { useRecipesStore } from '@/stores/recipes'
import { MealPlanType } from '@/models/MealPlan'

const MIN_STARTER_HALF_SERVE_SIZE_GRAMS = 200
const MAX_DOG_COUNT = 1

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStarterHalfExperiment = () => {
  const mealPlansStore = useMealPlansStore()
  const recipesStore = useRecipesStore()
  const flagsmith = useFlagsmith()

  const ENABLED_FOR_MULTI_DOGS = flagsmith.isEnabled('feature-starter-only-multi-dogs')
  const ENABLED_FOR_HYPO_PRO = true
  const ENABLED_FOR_LARGE_DOGS = flagsmith.isEnabled('feature-starter-only-large-dogs')
  const ENABLED_FOR_SMALL_DOGS = flagsmith.isEnabled('feature-starter-only-small-dogs')

  // Exclude half-starter for dogs with < 200g/day full-bowl serving size
  const eligibleForHalfStarter = computed<boolean>(() => {
    const [planWeight] = mealPlansStore.mealWeights

    return !!planWeight && planWeight.servingWeight >= MIN_STARTER_HALF_SERVE_SIZE_GRAMS
  })

  const eligible = computed<boolean>(() => {
    const planWeights = mealPlansStore.mealWeights

    // Exclude for customers with no plan data
    if (planWeights.length === 0) {
      return false
    }

    const isMultipleDogs = planWeights.length > MAX_DOG_COUNT

    // Exclude for customers with multiple dogs
    if (isMultipleDogs) {
      return ENABLED_FOR_MULTI_DOGS
    }

    const fullPlan = mealPlansStore.getMealPlanByType(MealPlanType.Full)

    const isWeeklyOrderFrequency = fullPlan?.frequency === 1

    // Whether to exclude for weekly plan frequencies
    if (isWeeklyOrderFrequency) {
      return ENABLED_FOR_LARGE_DOGS
    }

    const [planWeight] = planWeights
    const isSmallDog = planWeight && planWeight?.servingWeight < MIN_STARTER_HALF_SERVE_SIZE_GRAMS

    // Exclude for small dogs
    if (isSmallDog) {
      return ENABLED_FOR_SMALL_DOGS
    }

    const selectedRecipeNames = recipesStore.selectedRecipes.map((recipe) => recipe.name)

    // Exclude for customers with hypo-pro (goat)
    if (selectedRecipeNames.includes(RecipeName.Goat)) {
      return ENABLED_FOR_HYPO_PRO
    }

    return true
  })

  const enabled = computed<boolean>(() => {
    return eligible.value && useExperiments().inExperiment('known-bab-starter-half')
  })

  return {
    eligible,
    eligibleForHalfStarter,
    enabled,
  }
}

<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_160_5354)">
      <g clip-path="url(#clip0_160_5354)">
        <rect x="3" y="3" width="20" height="20" rx="10" fill="white" />
        <path
          d="M21.125 13C21.125 10.8451 20.269 8.77849 18.7452 7.25476C17.2215 5.73102 15.1549 4.875 13 4.875C10.8451 4.875 8.77849 5.73102 7.25476 7.25476C5.73102 8.77849 4.875 10.8451 4.875 13C4.875 15.1549 5.73102 17.2215 7.25476 18.7452C8.77849 20.269 10.8451 21.125 13 21.125C15.1549 21.125 17.2215 20.269 18.7452 18.7452C20.269 17.2215 21.125 15.1549 21.125 13ZM3 13C3 10.3478 4.05357 7.8043 5.92893 5.92893C7.8043 4.05357 10.3478 3 13 3C15.6522 3 18.1957 4.05357 20.0711 5.92893C21.9464 7.8043 23 10.3478 23 13C23 15.6522 21.9464 18.1957 20.0711 20.0711C18.1957 21.9464 15.6522 23 13 23C10.3478 23 7.8043 21.9464 5.92893 20.0711C4.05357 18.1957 3 15.6522 3 13ZM9.63281 9.45703C9.94141 8.58594 10.7695 8 11.6953 8H13.9727C15.3359 8 16.4375 9.10547 16.4375 10.4648C16.4375 11.3477 15.9648 12.1641 15.1992 12.6055L13.9375 13.3281C13.9297 13.8359 13.5117 14.25 13 14.25C12.4805 14.25 12.0625 13.832 12.0625 13.3125V12.7852C12.0625 12.4492 12.2422 12.1406 12.5352 11.9727L14.2656 10.9805C14.4492 10.875 14.5625 10.6797 14.5625 10.4688C14.5625 10.1406 14.2969 9.87891 13.9727 9.87891H11.6953C11.5625 9.87891 11.4453 9.96094 11.4023 10.0859L11.3867 10.1328C11.2148 10.6211 10.6758 10.875 10.1914 10.7031C9.70703 10.5312 9.44922 9.99219 9.62109 9.50781L9.63672 9.46094L9.63281 9.45703ZM11.75 16.75C11.75 16.4185 11.8817 16.1005 12.1161 15.8661C12.3505 15.6317 12.6685 15.5 13 15.5C13.3315 15.5 13.6495 15.6317 13.8839 15.8661C14.1183 16.1005 14.25 16.4185 14.25 16.75C14.25 17.0815 14.1183 17.3995 13.8839 17.6339C13.6495 17.8683 13.3315 18 13 18C12.6685 18 12.3505 17.8683 12.1161 17.6339C11.8817 17.3995 11.75 17.0815 11.75 16.75Z"
          fill="#10B193"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_160_5354"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_160_5354" />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.694118 0 0 0 0 0.576471 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_160_5354" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_160_5354" result="shape" />
      </filter>
      <clipPath id="clip0_160_5354">
        <rect x="3" y="3" width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { formatMoney } from '@/utils/formatMoney'
import { useTreatsStore } from '@/stores/treats'
import { useProductPrice } from '@/composables/useProductPrice'

const props = defineProps<{
  productId: number
  quantity: number
}>()

const treatsStore = useTreatsStore()
const { getDiscountedPrice, isProductDiscounted } = useProductPrice()

const treatProduct = computed(() => {
  return treatsStore.findProduct(props.productId)
})

const title = computed(() => treatProduct.value?.customerDisplayName)
const price = computed(() => (treatProduct.value?.price ?? 0) * props.quantity)
const description = computed(() => treatProduct.value?.skuSize)
</script>

<template>
  <tr>
    <th class="tw-text-left tw-px-6 tw-py-3">
      <div>{{ title }}</div>
      <div class="tw-pt-1 tw-font-light tw-text-sm">{{ quantity }} &times; {{ description }}</div>
    </th>
    <td class="tw-text-right tw-px-6 tw-py-3 tw-whitespace-nowrap tw-items-center tw-gap-1.5 tw-flex tw-justify-end">
      <s v-if="isProductDiscounted('treats')" class="tw-text-sm tw-text-opacity-40 tw-text-dark">
        {{ formatMoney(price) }}
      </s>
      {{ formatMoney(getDiscountedPrice('treats', price)) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { assetUrl } from '@lyka/utils'
import CheckoutProductBase from './products/CheckoutProductBase.vue'
import { formatMoney } from '@/utils/formatMoney'
import { useTreatsStore } from '@/stores/treats'
import { useProductPrice } from '@/composables/useProductPrice'

const props = defineProps<{
  productId: number
  quantity: number
  everyBox?: boolean
}>()

const treatsStore = useTreatsStore()
const { getDiscountedPrice, isProductDiscounted } = useProductPrice()

const treatProduct = computed(() => {
  return treatsStore.findProduct(props.productId)
})

const treatParent = computed(() => {
  if (treatProduct.value) {
    return treatsStore.findTreat(treatProduct.value.productHeroId)
  }

  return undefined
})

const title = computed(() => treatProduct.value?.customerDisplayName)
const sizeDescription = computed(() => treatProduct.value?.skuSize)
const price = computed(() => (treatProduct.value?.price ?? 0) * props.quantity)

const treatImageURL = computed(() => {
  if (treatParent.value) {
    return assetUrl(`images/treats/${treatParent.value.name}/packet.jpg`)
  }

  return undefined
})
</script>

<template>
  <CheckoutProductBase>
    <img
      :src="treatImageURL"
      width="64"
      height="64"
      class="tw-w-16 tw-h-16 tw-aspect-square tw-object-cover tw-rounded-lg tw-shrink-0"
      :alt="`Picture of ${title}`"
    />
    <div class="tw-flex tw-justify-between tw-w-full tw-gap-2">
      <div class="tw-space-y-2">
        <div>{{ quantity }} &times; {{ title }}</div>
        <div class="tw-font-light tw-text-sm">
          {{ sizeDescription }}
        </div>

        <div class="tw-text-xs tw-font-light">
          {{ everyBox ? 'Included in each delivery' : 'One-off purchase' }}
        </div>
      </div>

      <div class="tw-flex tw-gap-2 tw-items-baseline tw-text-right">
        <s v-if="isProductDiscounted('treats')" class="tw-text-sm tw-text-opacity-40 tw-text-dark">
          {{ formatMoney(price) }}
        </s>
        {{ formatMoney(getDiscountedPrice('treats', price)) }}
      </div>
    </div>
  </CheckoutProductBase>
</template>

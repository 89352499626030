<template>
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="tw-w-[1em] tw-h-[1em]"
  >
    <path
      d="M27.7466 41.6845L28.4756 42.4608L29.2046 41.6845L44.229 25.6845L42.771 24.3155L28.4756 39.5392L22.229 32.8869L20.771 34.256L27.7466 41.6845ZM63.5 32C63.5 49.1208 49.6208 63 32.5 63C15.3792 63 1.5 49.1208 1.5 32C1.5 14.8792 15.3792 1 32.5 1C49.6208 1 63.5 14.8792 63.5 32Z"
      stroke="#10B193"
      stroke-width="2"
    />
  </svg>
</template>

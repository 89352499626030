<script setup lang="ts">
import { computed, onMounted, ref, shallowRef, watchEffect } from 'vue'
import type { StripeElements, StripePaymentElement, StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { useStripe } from '@/services/useStripe'

const props = defineProps<{
  dollars: number
  paymentMethodConfiguration?: string
}>()

const emits = defineEmits<{
  (e: 'created', event: { elements: StripeElements; payment: StripePaymentElement }): void
  (e: 'change', event: StripePaymentElementChangeEvent): void
}>()

const elementsInstance = shallowRef<StripeElements>()
const element = ref<HTMLElement>()

const cents = computed(() => {
  // Dollars can be a float with more decimal places than we allow
  return Math.round(props.dollars * 100)
})

const mountStripePaymentElement = async (element: HTMLElement): Promise<void> => {
  const { elements, payment } = await useStripe().createPaymentElement({
    mode: cents.value > 0 ? 'payment' : 'setup',
    amount: cents.value,
    paymentMethodConfiguration: props.paymentMethodConfiguration,
  })

  elementsInstance.value = elements
  payment.mount(element)

  payment.on('ready', () => {
    emits('created', { elements, payment })
  })

  payment.on('change', (ev) => {
    emits('change', ev)
  })
}

const firstPaymentFree = ref(false)
const checkIfPaymentFree = async (): Promise<void> => {
  // going from free checkout to paid checkout
  if (cents.value === 0 && !firstPaymentFree.value && element.value instanceof HTMLElement) {
    firstPaymentFree.value = true
    await mountStripePaymentElement(element.value)
  }

  // going from paid checkout to free checkout
  if (cents.value > 0 && firstPaymentFree.value && element.value instanceof HTMLElement) {
    firstPaymentFree.value = false
    await mountStripePaymentElement(element.value)
  }
}

// If the price changes we need to update the StripeElements instance
watchEffect(async () => {
  await checkIfPaymentFree()

  elementsInstance.value?.update({
    amount: cents.value,
  })
})

onMounted(() => {
  if (cents.value === 0) {
    firstPaymentFree.value = true
  }

  if (element.value instanceof HTMLElement) {
    mountStripePaymentElement(element.value)
  }
})
</script>

<template>
  <div>
    <div ref="element" />
  </div>
</template>

import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { ref } from 'vue'

const DOWN_FOR_MAINTENANCE_FLAG = 'down-for-maintenance' as const

const isDownForMaintenance = ref(false)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDownForMaintenance = () => {
  const load = async (): Promise<boolean> => {
    const flags = useFlagsmith().getFlags()

    if (DOWN_FOR_MAINTENANCE_FLAG in flags) {
      isDownForMaintenance.value = flags[DOWN_FOR_MAINTENANCE_FLAG].enabled
    }

    return isDownForMaintenance.value
  }

  return {
    isDownForMaintenance,
    load,
  }
}

<script setup lang="ts">
import { LykaButton, LykaError, LykaInput, LykaMessage, LykaModal } from '@lyka/ui'
import { ref } from 'vue'
import { useBeenHereBeforeRequest } from '@/composables/useBeenHereBeforeRequest'
import { useBeenHereBeforeEvent } from '@/composables/events/segment/useBeenHereBeforeEvent'
import { useNotifications } from '@/composables/useNotifications'

defineProps<{
  open: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const email = ref('')
const success = ref<boolean>()
const loading = ref(false)

const close = (): void => {
  emits('close')
}

const submit = async ({ target }: Event): Promise<void> => {
  if (!(target instanceof HTMLFormElement)) {
    return
  }

  if (loading.value) {
    return
  }

  if (!target.checkValidity()) {
    return
  }

  success.value = undefined
  loading.value = true

  try {
    const { load } = useBeenHereBeforeRequest({ email: email.value })
    const result = await load()

    success.value = result
    useBeenHereBeforeEvent(email.value, result).send()

    if (result) {
      useNotifications().success("We've imported your previous data")
      close()
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <LykaModal :open="open" title="Resume Build a Box" @close="close">
    <form class="tw-space-y-4 tw-max-w-sm tw-mx-auto tw-text-center tw-mb-6" @submit.prevent="submit">
      <h3 class="tw-lead">Enter your email to resume</h3>
      <p>We'll fetch your saved details</p>

      <LykaInput
        v-model="email"
        name="email"
        label="Email"
        type="email"
        required
        error-message="Please enter your email address"
        hide-label
        placeholder="Your email address"
      >
        <template #suffix>
          <LykaButton type="submit" outline :loading="loading" class="tw--mr-5" name="resume-box-button">
            Go
          </LykaButton>
        </template>
      </LykaInput>

      <LykaError v-if="success === false"> We couldn't find any matches for those details </LykaError>
    </form>

    <LykaMessage class="tw-text-center tw-max-w-sm tw-mx-auto">
      Already have an account? <a href="/membership/login" class="tw-link">Log in</a>
    </LykaMessage>

    <template #footer>
      <div class="tw-w-full tw-flex tw-justify-center">
        <LykaButton type="button" variant="alt" decoration text-link @click.prevent="close"> Cancel </LykaButton>
      </div>
    </template>
  </LykaModal>
</template>

<script lang="ts" setup>
import { LykaMessage } from '@lyka/ui'
import { pluralize } from '@lyka/utils'
import { computed } from 'vue'
import { useMealPlansStore } from '@/stores/mealPlans'
import { type MealPlan, MealPlanType, getMealPlanName } from '@/models/MealPlan'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const mealPlansStore = useMealPlansStore()

const mealPlanName = computed<string>(() => {
  return getMealPlanName(props.mealPlan.type)
})

const fullPlanFrequency = computed(() => {
  return mealPlansStore.getMealPlanByType(MealPlanType.Full)?.frequency
})
</script>

<template>
  <LykaMessage>
    <p>
      After your 2 week {{ mealPlanName }}, your meals will be delivered
      <strong>every {{ fullPlanFrequency }} {{ pluralize(fullPlanFrequency, 'week') }}</strong
      >.
    </p>
    <p><small class="tw-small">You can adjust deliveries at anytime via your online account.</small></p>
  </LykaMessage>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import LykaButton from '@lyka/ui/components/LykaButton.vue'
import { useIntercom } from '@/composables/useIntercom'
import { useFrontChat } from '@/composables/useFrontChat'
import { useCoupons } from '@/composables/useCoupons'
import { useStateStore } from '@/stores/state'

const showSupportDialog = (): void => {
  if (useFrontChat().loaded) {
    useFrontChat().show()
  } else {
    useIntercom().show()
  }
}

const showResetButton = computed<boolean>(() => {
  return useCoupons().inPersonSalesCoupon.value
})

const reset = (): void => {
  // eslint-disable-next-line no-alert
  const confirmed = confirm('Are you sure you want to reset all of your data?')

  if (!confirmed) {
    return
  }

  useStateStore().resetState()
  location.reload()
}
</script>

<template>
  <header
    class="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-px-6 tw-h-[74px] tw-bg-alt tw-text-light tw-relative"
  >
    <div id="backBtn" class="tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-left-4" />
    <h1>
      <a href="/">
        <img src="@/assets/images/logo.svg" alt="Lyka" class="tw-h-10 lg:tw-h-auto" />
      </a>
    </h1>

    <div v-if="showResetButton" class="tw-absolute tw-right-4 tw-top-1/2 -tw-translate-y-1/2">
      <LykaButton size="sm" variant="secondary" type="button" @click="reset">Reset</LykaButton>
    </div>

    <button
      v-else
      aria-label="Support"
      class="tw-absolute md:tw-hidden tw-top-1/2 tw--translate-y-1/2 tw-right-4 tw-w-11 tw-h-11 tw-flex tw-items-center tw-justify-center"
      @click="showSupportDialog"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
        <path
          fill="currentColor"
          d="M13 18h-2v-2h2v2ZM8 10c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2H8Z"
        />
      </svg>
    </button>
  </header>
</template>

<script setup lang="ts">
import { LykaModal } from '@lyka/ui'
import { computed, onMounted } from 'vue'
import { MealPlanType } from '../../../models/MealPlan'
import { type MealPlanWeight, caloriesFromGrams } from '@/models/MealPlanWeight'
import { type MealPlan } from '@/models/MealPlan'
import { usePlanCaloriesModalShownEvent } from '@/composables/events/segment/usePlanCaloriesModalShownEvent'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const pouchSizeGrams = computed<number>(() => {
  if (props.mealPlan.type === MealPlanType.Trial) {
    return 125
  }

  return 200
})

const pouchQuantity = computed<number>(() => {
  if (props.mealPlan.type === MealPlanType.Trial7x200g) {
    return 7
  }

  return 14
})

const trialPouchCalories = caloriesFromGrams(pouchSizeGrams.value)

const caloriesPerDay = (mealWeight: MealPlanWeight): number => {
  return caloriesFromGrams(mealWeight.servingWeight)
}

const percentOfCalories = (mealWeight: MealPlanWeight): number => {
  return (trialPouchCalories / caloriesPerDay(mealWeight)) * 100
}

const mealWeight = computed<undefined | MealPlanWeight>(() => {
  return props.mealWeights[0]
})

const pouchesPerDay = computed<string>(() => {
  if (props.mealPlan.type === MealPlanType.Trial7x200g) {
    return `1/2`
  }

  return `1`
})

const remainingCaloriesPercentage = (mealWeight: MealPlanWeight): number => {
  const percentage = 100 - percentOfCalories(mealWeight)

  return Math.ceil(percentage / 5) * 5
}

onMounted(() => {
  usePlanCaloriesModalShownEvent().send({
    mealPlan: props.mealPlan,
  })
})
</script>

<template>
  <LykaModal open closable @close="emits('close')">
    <div class="tw-space-y-6 tw-font-light">
      <h3 class="tw-h3">Lyka Trial Box</h3>

      <div v-if="mealWeight" class="tw-text-left tw-text-base tw-flex tw-flex-col tw-gap-6">
        <p>
          Trial Box is designed to give your dog a small taste test of Lyka’s fresh meals, allowing them to sample it
          alongside their current food before receiving their full sized order.
        </p>

        <hr class="tw-text-[#DBE6DC]" />

        <div>
          <h4 class="tw-font-bold tw-text-lg">Recommended Feeding Guide</h4>
          <p>
            For {{ pouchQuantity }}x{{ pouchSizeGrams }}g Taster pouches<br /><br />
            Feed
            <strong
              >{{ pouchesPerDay }} pouch per day + {{ remainingCaloriesPercentage(mealWeight).toFixed(0) }}% of their
              usual food</strong
            >
            to meet their daily intake needs. <br /><br />
            Your Trial Box includes a full feeding guide to help you ensure a smooth introduction to Lyka.
          </p>
        </div>

        <hr class="tw-text-[#DBE6DC]" />

        <div>
          <h4 class="tw-font-bold tw-text-lg">After the trial</h4>
          <p>
            Your next delivery will include {{ mealWeight.name }}'s full daily serving size, customised to their needs.
            This order is set to arrive 2 weeks after your trial begins, and you’ll receive an SMS and email reminder
            before it’s processed.
          </p>
          <br />
          <p>You can update or cancel your order anytime in your account.</p>
        </div>
      </div>
    </div>
  </LykaModal>
</template>

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  maximumFractionDigits: 2,
})

export const formatMoney = (dollars: number, stripEmptyCents = true): string => {
  const value = formatter.format(dollars)

  if (stripEmptyCents) {
    // Strip cents if there aren't any
    return value.replace(/\D00(?=\D*$)/, '')
  }

  return value
}

import camelcaseKeys from 'camelcase-keys'
import type { StateTerritory } from '@/models/Address'
import env from '@/env'

export interface AddressSearchResponse {
  completions: Array<AddressSearchResult>
  paid: boolean
  demo: boolean
  success: boolean
}

export interface AddressSearchResult {
  id: string
  fullAddress: string
  canonicalAddressId: string
}

export interface AddressInfo {
  success: boolean
  id: string
  fullAddress: string
  addressLine1: string
  addressLine2?: string
  localityName: string // AKA postcode
  stateTerritory: StateTerritory
  postcode: string
}

const transform = (data: Record<string, any>): Record<string, any> => {
  return camelcaseKeys(data, { deep: true })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAddressFinder = () => {
  const addressInfo = async (addressId: string): Promise<AddressInfo> => {
    const params = new URLSearchParams({
      key: env.VITE_ADDRESS_FINDER_KEY,
      format: 'json',
      gps: String(0),
      id: addressId,
    })
    const url = new URL(`https://api.addressfinder.io/api/au/address/info?${params.toString()}`)
    const response = await fetch(url.toString(), {
      mode: 'cors',
      headers: {
        accept: 'application/json',
      },
    })
    const data = await response.json()
    return transform(data) as AddressInfo
  }

  const addressSearch = async (address: string): Promise<AddressSearchResponse> => {
    const params = new URLSearchParams({
      key: env.VITE_ADDRESS_FINDER_KEY,
      format: 'json',
      gnaf: String(1),
      post_box: String(0),
      q: address,
    })
    const url = new URL(`https://api.addressfinder.io/api/au/address/autocomplete?${params.toString()}`)
    const response = await fetch(url.toString(), {
      mode: 'cors',
      headers: {
        accept: 'application/json',
      },
    })
    const data = await response.json()
    return transform(data) as AddressSearchResponse
  }

  return {
    addressInfo,
    addressSearch,
  }
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="187"
    height="110"
    viewBox="0 0 187 110"
    class="tw-fill-current tw-max-w-full tw-h-auto"
  >
    <title>Lyka</title>
    <path
      fill-rule="evenodd"
      d="M0 79.08V0h14.385v79.08H0Zm172.615-57.678H187V79.08h-14.385v-4.16a28.533 28.533 0 0 1-15.211 4.376 29.168 29.168 0 0 1-20.598-8.51 29.02 29.02 0 0 1-8.531-20.545 29.02 29.02 0 0 1 8.531-20.544 29.168 29.168 0 0 1 20.598-8.51 28.629 28.629 0 0 1 15.211 4.364v-4.149ZM157.404 64.84c2.9.026 5.743-.808 8.167-2.396a14.61 14.61 0 0 0 5.449-6.524 14.563 14.563 0 0 0 .886-8.445 14.584 14.584 0 0 0-3.974-7.51 14.653 14.653 0 0 0-7.492-4.032 14.673 14.673 0 0 0-8.475.809 14.629 14.629 0 0 0-6.59 5.375 14.573 14.573 0 0 0-2.476 8.124 14.531 14.531 0 0 0 4.226 10.29 14.608 14.608 0 0 0 10.279 4.31ZM59.193 37.687V21.402h14.384v45.746a43.272 43.272 0 0 1-9.326 26.866 42.97 42.97 0 0 1-26.66 15.771 18.546 18.546 0 0 1-13.065-2.882 14.476 14.476 0 0 0 7.408-2.546 22.145 22.145 0 0 1-8.856-3.909 22.07 22.07 0 0 1-6.332-7.307c-.216-.418-.42-.837-.623-1.255l.66.383a28.374 28.374 0 0 0 10.152 3.383h.456a28.438 28.438 0 0 0 11.196-.933 22.755 22.755 0 0 0 1.738-.561 28.876 28.876 0 0 0 10.367-6.589 28.785 28.785 0 0 0 6.679-10.294 25.794 25.794 0 0 1-9.902 1.973 26.31 26.31 0 0 1-18.513-7.765 26.174 26.174 0 0 1-7.607-18.54v-31.6h14.385v31.6a11.705 11.705 0 0 0 3.444 8.294 11.761 11.761 0 0 0 8.315 3.436 11.787 11.787 0 0 0 8.315-3.436 11.728 11.728 0 0 0 3.445-8.294V37.627l-.06.06Zm41.212 18.7a40.19 40.19 0 0 1-5.514 1.1V79.08H80.506V0H94.89v42.852c.829-.165 1.649-.372 2.457-.621.455-.132.899-.276 1.354-.443a26.815 26.815 0 0 0 10.573-7.042 27.85 27.85 0 0 0 2.697-3.432 27.127 27.127 0 0 0 2.985-6.062 26.807 26.807 0 0 0 1.474-8.812v-1.626c0-.31-.024-.622-.072-.932l.372.346.372.395a22.2 22.2 0 0 1 6.077 14.826 9.126 9.126 0 0 0 2.865-5.022 14.802 14.802 0 0 1-.467 12.303 42.92 42.92 0 0 1-.935 1.567 41.482 41.482 0 0 1-7.588 9.063 40.537 40.537 0 0 1-3.44 2.762l20.102 29.126h-17.489L100.464 56.41l-.059-.024Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  address: string
}>()

const mapDimensions = {
  height: 252,
  width: 448,
} as const

const mapURL = computed<string | undefined>(() => {
  const key = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

  if (!key) {
    return undefined
  }

  const url = new URL('https://maps.googleapis.com/maps/api/staticmap')

  const style: Record<string, string> = {
    // Hide landmarks from the map
    'feature:poi': 'visibility:off',
  }

  // Convert the style object into a query string
  const styles = Object.entries(style).map(([feature, visibility]) => `${feature}|${visibility}`)

  const { width, height } = mapDimensions

  url.searchParams.set('center', props.address)
  url.searchParams.set('zoom', '17')
  url.searchParams.set('size', [width, height].join('x'))
  url.searchParams.set('maptype', 'roadmap')
  url.searchParams.set('region', 'au')
  url.searchParams.set('key', import.meta.env.VITE_GOOGLE_MAPS_API_KEY)
  url.searchParams.set('style', styles.join('|'))

  return url.toString()
})
</script>

<template>
  <div
    class="sm:tw-rounded-xl tw-isolate tw-bg-mint-green tw-overflow-hidden tw-aspect-video tw-max-w-md tw-w-full tw-mx-auto tw-relative md:tw-mx-0 checkout-completed-map"
  >
    <img
      :width="mapDimensions.width"
      :height="mapDimensions.height"
      :src="mapURL"
      class="sm:tw-rounded-xl"
      alt="Map of your delivery location"
    />
    <div
      class="tw-bg-dark tw-text-white tw-absolute tw-text-center tw-bottom-1/2 tw-left-1/2 tw-max-w-48 tw-rounded-lg tw-text-xs tw-p-3 -tw-translate-y-2 -tw-translate-x-1/2 tw-font-light"
    >
      <div class="tw-text-white/80">Delivery address</div>
      <div>{{ address }}</div>
      <div
        class="tw-absolute tw-top-full tw-left-1/2 -tw-translate-x-1/2 tw-border-t-dark tw-border-t-8 tw-border-l-8 tw-border-r-8"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.checkout-completed-map {
  grid-area: map;
}
</style>

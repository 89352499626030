import {
  experimentsFromURL,
  filterForcedExperiments,
  useExperiments,
} from '@lyka/vue-common/composables/useExperiments'
import { experimentsFromFlags, useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { ref } from 'vue'
import { useExperimentEvents, useExperimentForcedEvents } from './events/segment/useExperimentEvents'

const loaded = ref(false)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAnonymousUserExperiments = () => {
  const load = async (): Promise<void> => {
    if (loaded.value) {
      return
    }

    const anonymousId = await useSegment().getAnonymousId()

    const anonymousFlags = await useFlagsmith().loadFlags(anonymousId)

    if (!anonymousFlags) {
      return
    }

    const anonymousExperiments = experimentsFromFlags(anonymousFlags)

    // Get the 'forced' experiments from the URL (these will override the experiments from flags)
    const forcedExperiments = filterForcedExperiments(experimentsFromURL(location.href), anonymousExperiments)

    useExperiments().setExperiments({ ...anonymousExperiments, ...forcedExperiments })

    // Fire the events for the experiments
    useExperimentEvents(anonymousExperiments).send()

    // Fire the events for the forced experiments
    useExperimentForcedEvents(forcedExperiments).send()

    loaded.value = true
  }

  return {
    load,
  }
}

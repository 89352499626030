<script setup lang="ts">
import { LykaButton, LykaError, LykaInput, LykaSpinner } from '@lyka/ui'
import { computed, onUpdated, ref, watch } from 'vue'
import CheckoutDeliveryDatePicker from './CheckoutDeliveryDatePicker.vue'
import { DeliveryDateType } from '@/steps/checkout'
import type { DeliveryData } from '@/composables/useCheckout'
import { useDeliveryDates } from '@/composables/useDeliveryDates'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { postcodeSchema } from '@/schemas/postcode'
import type { DeliverySlot, DeliverySlotName } from '@/models/DeliverySlot'

const props = defineProps<{
  postcode: string
  delivery: DeliveryData
}>()

const emits = defineEmits<{
  (e: 'update', value: typeof props.delivery): void
  (e: 'update:slotName', value: DeliverySlotName | undefined): void
  (e: 'expanded', open: boolean): void
}>()

const deliveryDates = useDeliveryDates()

const instructions = computed({
  get() {
    return props.delivery.instructions
  },
  set(value: string) {
    emits('update', {
      ...props.delivery,
      instructions: value,
    })
  },
})

const instructionsShown = ref(!!instructions.value)

const slotsForDate = computed(() => {
  if (props.delivery.date) {
    return deliveryDates.getSlotsForDate(props.delivery.date)
  }

  return []
})

const sendDeliveryTimeChangedEvent = (slotCutOffId: number): void => {
  const amOrPM = slotsForDate.value.find((slot) => slot.cutoffId === slotCutOffId)?.name?.toUpperCase() // 'AM' or 'PM'

  if (amOrPM) {
    useGTM().sendAction(GTMEventAction.CHANGED_DELIVERY_TIME, amOrPM)
  }
}

const slot = computed({
  get() {
    return props.delivery.slot
  },
  set(value) {
    if (value) {
      sendDeliveryTimeChangedEvent(value)
    }

    emits('update', {
      ...props.delivery,
      slot: value,
    })
    emits('update:slotName', slotsForDate.value.find((slot) => slot.cutoffId === value)?.name)
  },
})

const sendDeliveryDateChangedEvent = (newDate: string): void => {
  const defaultDate = deliveryDates.defaultDeliveryDate.value?.date

  const eventLabel = newDate === defaultDate ? 'Default date' : 'Custom date'

  useGTM().sendAction(GTMEventAction.CHANGED_DELIVERY_DATE, eventLabel)
}

const date = computed({
  get() {
    return props.delivery.date ?? ''
  },
  set(value) {
    sendDeliveryDateChangedEvent(value)

    // If the date changes then we need to update to a slot within that date
    const datesForSlot = value ? deliveryDates.getSlotsForDate(value) : []

    const currentSlotName = slotsForDate.value.find((slot) => slot.cutoffId === props.delivery.slot)?.name

    const preferredSlot = datesForSlot.find((slot) => slot.name === currentSlotName)

    const slot: DeliverySlot | undefined = preferredSlot ?? datesForSlot.at(0)

    emits('update', {
      ...props.delivery,
      date: value || null,

      slot: slot?.cutoffId ?? null,
    })
    emits('update:slotName', datesForSlot[0]?.name)
  },
})

const setDeliveryDateToSoonest = (): void => {
  emits('update', {
    instructions: instructions.value,
    type: DeliveryDateType.Soonest,
    date: deliveryDates.defaultDeliveryDate.value?.date ?? null,
    slot: deliveryDates.defaultDeliverySlot.value?.cutoffId ?? null,
  })
}

const resetDeliveryDate = (): void => {
  emits('update', {
    instructions: instructions.value,
    type: DeliveryDateType.Soonest,
    date: null,
    slot: null,
  })
}

const getPostcodeValid = (postcode: string): boolean => {
  const { success } = postcodeSchema.safeParse(postcode)

  return success
}

const verifyDeliverySlot = (): void => {
  if (props.delivery.slot) {
    const valid = deliveryDates.checkDeliverySlotValid(props.delivery)

    if (valid) {
      return
    }
  }

  setDeliveryDateToSoonest()
}

watch(
  () => props.postcode,
  async (newPostcode, oldPostcode) => {
    deliveryDates.clearDeliveryDates()

    if (oldPostcode && newPostcode !== oldPostcode) {
      resetDeliveryDate()
    }

    if (!getPostcodeValid(newPostcode)) {
      return
    }

    await deliveryDates.loadDeliveryDates(newPostcode)

    verifyDeliverySlot()
  },
  { immediate: true },
)

const showInstructions = (): void => {
  useGTM().sendAction(GTMEventAction.ADDED_DELIVERY_INSTRUCTIONS)

  instructionsShown.value = true
}

const onExpandedPicker = (open: boolean): void => emits('expanded', open)

onUpdated(async () => {
  emits('update:slotName', slotsForDate.value.find((s) => s.cutoffId === slot.value)?.name)
})
</script>

<template>
  <LykaSpinner v-if="deliveryDates.loading.value" />

  <LykaError v-else-if="postcode && !slotsForDate.length">
    We're sorry. Currently we are not delivering to this postcode
  </LykaError>

  <div v-else>
    <CheckoutDeliveryDatePicker
      v-model="date"
      v-model:slot="slot"
      :slots="slotsForDate"
      :dates="deliveryDates.availableDeliveryDates.value"
      :soonest-delivery-date="deliveryDates.defaultDeliveryDate.value?.date"
      @expanded="onExpandedPicker"
    >
      <template #suffix>
        <slot name="suffix" />
      </template>
    </CheckoutDeliveryDatePicker>

    <div class="tw-flex tw-mt-2">
      <LykaButton
        v-if="!instructionsShown"
        id="delivery-instructions"
        decoration
        text-link
        variant="success"
        @click="showInstructions"
      >
        + Add delivery instructions
      </LykaButton>

      <LykaInput
        v-if="instructionsShown"
        v-model="instructions"
        name="delivery-instructions"
        class="tw-mt-2"
        label="Delivery instructions"
        textarea
        placeholder="Eg. Leave at front door&hellip;"
      />
    </div>
  </div>
</template>

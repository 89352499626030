<script setup lang="ts">
import { LykaButton, LykaCloseIcon, LykaError, LykaInput } from '@lyka/ui'
import { computed, onMounted, ref } from 'vue'
import type { Coupon } from '@/models/Coupon'
import { useCoupons } from '@/composables/useCoupons'
import type { MealPlanType } from '@/models/MealPlan'
import { useCouponAppliedSuccessEvent } from '@/composables/events/segment/useCouponAppliedSuccessEvent'
import { useCouponAppliedFailureEvent } from '@/composables/events/segment/useCouponAppliedFailureEvent'
import { useCouponRemovedEvent } from '@/composables/events/segment/useCouponRemovedEvent'
import { useCheckout } from '@/composables/useCheckout'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  coupon?: Coupon
  email: string
  mealPlanType?: MealPlanType
}>()

const emits = defineEmits<{
  (e: 'change', coupon: Coupon): void
  (e: 'remove'): void
}>()

const loading = ref(false)
const errorMessage = ref()
const dirty = ref(false)

const coupons = useCoupons()

const { mealPlanPrice, totalDiscount } = useCheckout()

const initialCoupon = computed(() => {
  // default to dollar discount coupon if it was attempted to be used
  if (coupons.dollarDiscountCouponAttempt.value) {
    const dollarDiscountCoupon = coupons.fetchCouponFromURL()

    if (dollarDiscountCoupon) {
      return dollarDiscountCoupon
    }
  }

  return props.coupon?.couponCode ?? ''
})

const code = ref(initialCoupon.value)

const onInput = (): void => {
  dirty.value = true
}

const disabled = computed(() => {
  return !code.value.length || loading.value
})

const removeCoupon = (): void => {
  code.value = ''
  emits('remove')
}

const removeDollarDiscountCoupon = (): void => {
  if (!coupons.dollarDiscountCouponAttempt.value) {
    return
  }

  if (code.value !== initialCoupon.value) {
    return
  }

  // remove coupon if dollar discount
  emits('remove')
  code.value = initialCoupon.value
}

const handleRemoveCoupon = (): void => {
  if (props.coupon) {
    useCouponRemovedEvent(props.coupon).send()
  }

  removeCoupon()
}

const applyCoupon = (coupon: Coupon): void => {
  emits('change', coupon)
}

const validateCoupon = async (): Promise<void> => {
  if (!dirty.value) {
    return
  }

  errorMessage.value = undefined
  dirty.value = false

  if (props.coupon) {
    useCouponRemovedEvent(props.coupon).send()
  }

  if (!code.value) {
    removeCoupon()
    return
  }

  try {
    loading.value = true
    const { coupon, success, error } = await useCoupons().verifyCoupon(
      code.value,
      props.email,
      props.mealPlanType,
      mealPlanPrice.value,
    )

    if (success && coupon) {
      useCouponAppliedSuccessEvent(coupon).send()
      applyCoupon(coupon)
    } else if (error) {
      useCouponAppliedFailureEvent(code.value).send()

      errorMessage.value = error

      removeDollarDiscountCoupon()
    } else {
      removeCoupon()
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  if (coupons.dollarDiscountCouponAttempt.value) {
    dirty.value = true
    await validateCoupon()
  }
})
</script>

<template>
  <div>
    <div v-if="coupon" class="tw-flex tw-justify-between tw-items-center">
      <div class="tw-bg-mint-green tw-rounded-lg tw-px-3 tw-py-2 tw-items-center tw-flex tw-gap-2">
        <span>{{ coupon.discount }}% first box discount</span>
        <button title="Remove" @click="handleRemoveCoupon"><LykaCloseIcon /></button>
      </div>

      <div class="tw-text-green">-{{ formatMoney(totalDiscount) }}</div>
    </div>

    <div v-else>
      <LykaInput
        v-model="code"
        name="coupon"
        placeholder="Referral or promo code"
        label="Referral or promo code"
        hide-label
        :disabled="loading"
        @change="validateCoupon"
        @input="onInput"
      >
        <template #suffix>
          <div class="tw-flex tw-gap-2">
            <button
              v-if="coupon"
              id="checkout-remove-coupon"
              type="button"
              title="Remove coupon"
              @click="handleRemoveCoupon"
            >
              <LykaCloseIcon />
            </button>
            <LykaButton
              type="button"
              :disabled="disabled"
              outline
              class="tw--mr-5"
              :loading="loading"
              @click="validateCoupon"
            >
              {{ coupon ? 'Applied' : 'Apply' }}
            </LykaButton>
          </div>
        </template>
      </LykaInput>

      <LykaError v-if="errorMessage">
        {{ errorMessage }}
      </LykaError>
    </div>
  </div>
</template>

import { roundMoney } from '@/utils/roundMoney'

export interface CouponReferrer {
  uuid: string
}

export type DiscountProductType = 'all' | 'meals' | 'treats'

export interface Coupon {
  couponCode: string
  couponType: string
  currentReferCampaign?: string
  discount: number
  discountType: DiscountProductType
  discountAmountType?: 'percentage' | 'dollar'
  validateMessage: string
  referrer?: CouponReferrer
  multiBoxDiscountEnabled?: boolean
  multiBoxDiscounts?: Array<{
    box: number
    discount: number
  }>
  couponOwner?: string
}

export const getDiscountedPrice = (
  price: number,
  coupon?: Partial<Pick<Coupon, 'discount' | 'discountAmountType' | 'multiBoxDiscountEnabled' | 'multiBoxDiscounts'>>,
  boxNumber?: number, // sequentual number so it is 1-based
): number => {
  if (!coupon) {
    return price
  }

  let discount = 0

  if (boxNumber === undefined) {
    discount = coupon?.discount ?? 0
  } else if (coupon.multiBoxDiscountEnabled) {
    discount = coupon.multiBoxDiscounts?.[boxNumber - 1]?.discount ?? 0
  }

  if (discount && coupon.discountAmountType === 'dollar') {
    return roundMoney(Math.max(price - discount, 0))
  }

  if (discount) {
    return roundMoney(price * (1 - discount / 100))
  }

  return price
}

export const isProductTypeDiscounted = (
  productType: DiscountProductType,
  coupon?: Partial<Pick<Coupon, 'discountType' | 'multiBoxDiscounts'>>,
  boxNumber?: number,
): boolean => {
  if (!coupon) {
    return false
  }

  const discounted = coupon.discountType === 'all' || coupon.discountType === productType

  if (boxNumber === undefined) {
    return discounted
  }

  const discount = coupon.multiBoxDiscounts?.at(boxNumber - 1)?.discount ?? 0

  if (discount) {
    return true
  }

  return false
}

export const getCouponDiscountDescription = (coupon: Coupon): string => {
  if (coupon.discountAmountType === 'dollar') {
    return `$${coupon.discount} off`
  }

  return `${coupon.discount}% off`
}

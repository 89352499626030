<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, shallowRef, watch } from 'vue'
import { clone } from 'remeda'
import {
  LykaButton,
  LykaCollapsibleForm,
  LykaCollapsibleFormGroup,
  LykaContainer,
  LykaError,
  LykaInput,
  LykaSpinner,
} from '@lyka/ui'
import type { StripeElements, StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import StripePayment from '../StripePayment.vue'
import StepBackButton from '../StepBackButton.vue'
import CheckoutHowDidYouHear from './checkout/CheckoutHowDidYouHear.vue'
import CheckoutPassword from './checkout/CheckoutPassword.vue'
import CheckoutSeparator from './checkout/CheckoutSeparator.vue'
import CheckoutSubmitButton from './checkout/CheckoutSubmitButton.vue'
import CheckoutCoupon from './checkout/CheckoutCoupon.vue'
import CheckoutTerms from './checkout/CheckoutTerms.vue'
import CheckoutCreditCards from './checkout/CheckoutCreditCards.vue'
import CheckoutMobilePhone from './checkout/CheckoutMobilePhone.vue'
import CheckoutFatalError from './checkout/CheckoutFatalError.vue'
import CheckoutOrderSummary from './checkout/CheckoutOrderSummary.vue'
import CheckoutInfo from './checkout/CheckoutInfo.vue'
import CheckoutLayout from './checkout/CheckoutLayout.vue'
import CheckoutFooter from './checkout/CheckoutFooter.vue'
import CheckoutPriceSummary from './checkout/CheckoutPriceSummary.vue'
import CheckoutHeading from './checkout/CheckoutHeading.vue'
import CheckoutDeliveryDate from './checkout/CheckoutDeliveryDate.vue'
import CheckoutAddress from './checkout/CheckoutAddress.vue'
import CheckoutOrderSummaryRedesign from './checkout/summary/CheckoutOrderSummaryRedesign.vue'
import CheckoutRedesignCoupon from './checkout/summary/CheckoutRedesignCoupon.vue'
import TrustpilotReviews from '@/components/TrustpilotReviews.vue'
import {
  CheckoutAccountSchema,
  CheckoutDeliverySchema,
  type CheckoutStepAddressData,
  type CheckoutStepData,
} from '@/steps/checkout'
import { type DeliveryData, type PaymentMethod, useCheckout } from '@/composables/useCheckout'
import { type Coupon } from '@/models/Coupon'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { AcquisitionPage, useAcquisitionData } from '@/composables/useAcquisitionData'
import { useMealPlansStore } from '@/stores/mealPlans'
import { useUserStore } from '@/stores/user'
import { formatDate } from '@/utils/formatDate'
import { formatMoney } from '@/utils/formatMoney'
import type { DeliverySlotName } from '@/models/DeliverySlot'
import env from '@/env'
import { useDeliveryDates } from '@/composables/useDeliveryDates'
import { useRecipesStore } from '@/stores/recipes'
import type { Recipe } from '@/models/Recipe'
import type { MealPlan } from '@/models/MealPlan'

const props = defineProps<{
  stepData: CheckoutStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const {
  mealPlan,
  mealWeights,
  treatQuantities,
  treatsInEveryBox,
  totalPrice,
  appliedCoupon,
  discount,
  error,
  fatalError,
  discountedTotalPrice,
  completePurchase,
  applyCoupon,
  removeCoupon,
} = useCheckout()

const { getTimeForSlot } = useDeliveryDates()
const recipesStore = useRecipesStore()

const data = reactive(clone(props.stepData))
const stripeElements = shallowRef<StripeElements>()
const stripeElementsComplete = ref(false)
const paymentMethod = ref<PaymentMethod>('credit_card')
const password = ref('')
const loading = ref(false)
const postcodeInvalid = ref(false)
const submitting = ref(false)
const userStore = useUserStore()
const stripeError = ref<string>()
const activeSlotName = ref<DeliverySlotName>()

const mealPlansStore = useMealPlansStore()

const disabled = computed(() => {
  return !stripeElementsComplete.value || !password.value || !props.valid || loading.value || submitting.value
})

const onStripePaymentElementCreate = ({ elements }: { elements: StripeElements }): void => {
  stripeElements.value = elements
}

const showOrderSummaryRedesign = useFlagsmith().isEnabled('feature-checkout-order-summary-redesign')

const determinePaymentMethod = (stripePaymentType: string): PaymentMethod => {
  if (stripePaymentType === 'card') {
    return 'credit_card'
  }

  return stripePaymentType as PaymentMethod
}

const onStripePaymentElementChange = async (event: StripePaymentElementChangeEvent): Promise<void> => {
  stripeElementsComplete.value = false

  if (!event.complete || !stripeElements.value) {
    return
  }

  const isCreditCard = ['card', 'link'].includes(event.value.type)

  // stripe returns either 'card', 'link' or 'apple_pay', to keep backend values consistent this value is converted
  paymentMethod.value = determinePaymentMethod(event.value.type)

  // handle credit card (and link)
  if (isCreditCard) {
    // validate form
    const result = await stripeElements.value.submit()

    if (result.error) {
      throw new Error(`Error validating payment details${result.error.message ? `: ${result.error.message}` : ''}`)
    }

    stripeElementsComplete.value = true

    return
  }

  if (event.value.type === 'apple_pay') {
    stripeElementsComplete.value = true
  }
}

const updateAddress = (newAddress: CheckoutStepAddressData): void => {
  data.address = newAddress
}

const updateDelivery = (delivery: DeliveryData): void => {
  data.delivery = delivery
}

const updateCoupon = (coupon: Coupon): void => {
  if (coupon) {
    useGTM().sendAction(GTMEventAction.APPLIED_DISCOUNT, coupon.discount)
  }

  applyCoupon(coupon)
}

const updatePassword = (value: string): void => {
  password.value = value
}

const updateHowDidYouHear = (howDidYouHear: { option: number | null; other: string }): void => {
  data.howDidYouHear = howDidYouHear
}

const timeForSlot = (slotName?: DeliverySlotName): string | undefined => {
  if (slotName) {
    return getTimeForSlot({ name: slotName })
  }

  return undefined
}

const errorMessage = computed(() => {
  return stripeError.value ?? error.value
})

const handleSubmit = async (): Promise<void> => {
  submitting.value = true
  stripeError.value = undefined

  const checkoutSuccessful = await completePurchase(stripeElements.value!, password.value, paymentMethod.value)
  if (checkoutSuccessful) {
    emits('submit', data)
  }

  submitting.value = false
}

onBeforeMount(() => {
  const { firstName, email } = userStore.user

  Object.assign(data.user, {
    firstName,
    email,
  })
})

onMounted(async () => {
  loading.value = true

  try {
    await mealPlansStore.loadMeals()
    useAcquisitionData().send(AcquisitionPage.Checkout)
  } finally {
    loading.value = false
  }
})

watch(data, (newData) => emits('save', newData), { deep: true })

const accountDetailsValid = computed<boolean>(() => {
  return (CheckoutAccountSchema.safeParse(props.stepData)?.success ?? false) && !!password.value
})

const deliveryDetailsValid = computed<boolean>(() => {
  return CheckoutDeliverySchema.safeParse(props.stepData)?.success ?? false
})

const paymentMethodConfiguration = computed<string>(() => {
  return env.VITE_STRIPE_PAYMENT_METHOD_CONFIGURATION_ID_APPLE_PAY
})

const recipes = computed<Recipe[]>(() => {
  return recipesStore.selectedRecipes
})

const allMealPlans = computed<MealPlan[]>(() => {
  return mealPlansStore.mealPlans
})
</script>

<template>
  <div
    v-if="loading"
    class="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-alt tw-w-full tw-mx-auto tw-pt-32 tw-px-6 tw-space-y-10 tw-grow tw-overflow-auto tw-mb-8 tw-max-w-xl"
  >
    <LykaSpinner />
  </div>

  <LykaContainer :padding="false" size="lg">
    <CheckoutHeading />

    <CheckoutFatalError v-if="fatalError" />

    <StepBackButton :disabled="submitting" header @click="emits('previous')" />

    <CheckoutLayout>
      <template v-if="errorMessage" #error>
        <LykaError>
          {{ errorMessage }}
        </LykaError>
      </template>

      <template #form>
        <LykaCollapsibleFormGroup @submit="handleSubmit">
          <LykaCollapsibleForm :invalid="!accountDetailsValid" class="tw-px-6 tw-pt-6 lg:tw-pt-0 lg:tw-px-0" active>
            <template #title>
              <h4 class="tw-h4">Account details</h4>
            </template>

            <template #description>
              <span class="tw-text-sm tw-font-light">
                Easily manage your plan, deliveries and profile from your online account.
              </span>
            </template>

            <template #form>
              <fieldset id="customer-details" class="tw-space-y-6">
                <div class="tw-flex tw-flex-col tw-gap-y-6">
                  <LykaInput
                    v-model.trim="data.user.firstName"
                    name="first-name"
                    label="First name"
                    required
                    autocapitalize
                    error-message="Your first name is required"
                  />
                  <LykaInput
                    v-model.trim="data.user.lastName"
                    name="last-name"
                    label="Last name"
                    required
                    autocapitalize
                    error-message="Your last name is required"
                  />
                  <LykaInput
                    v-model="data.user.email"
                    name="email"
                    class="lg:tw-col-span-2"
                    label="Email Address"
                    type="email"
                    required
                    error-message="Your email address is required"
                  />

                  <CheckoutMobilePhone v-model:mobile="data.user.mobile" v-model:sms-opt-in="data.user.smsOptIn" />

                  <CheckoutHowDidYouHear
                    :option="data.howDidYouHear.option"
                    :other="data.howDidYouHear.other"
                    @update="updateHowDidYouHear"
                  />

                  <CheckoutPassword @update="updatePassword" />
                </div>
              </fieldset>
            </template>

            <template #summary>
              <div class="tw-flex tw-flex-col tw-space-y-1 tw-font-light">
                <span>{{ data.user.firstName }} {{ data.user.lastName }}</span>
                <span>{{ data.user.email }}</span>
                <span>{{ data.user.mobile }}</span>
              </div>
            </template>
          </LykaCollapsibleForm>

          <LykaCollapsibleForm :invalid="!deliveryDetailsValid" class="tw-px-6 tw-pt-6 lg:tw-px-0">
            <template #title>
              <h4 class="tw-h4">Delivery</h4>
            </template>

            <template #form>
              <fieldset id="delivery-details">
                <div class="tw-space-y-6 tw-mt-6">
                  <CheckoutAddress :address="data.address" :invalid="postcodeInvalid" @update="updateAddress" />

                  <CheckoutDeliveryDate
                    v-model:slot-name="activeSlotName"
                    :postcode="data.address.postcode"
                    :delivery="data.delivery"
                    @update="updateDelivery"
                  >
                    <template #suffix>
                      <LykaButton variant="secondary" size="sm">Edit</LykaButton>
                    </template>
                  </CheckoutDeliveryDate>
                </div>
              </fieldset>
            </template>

            <template #summary>
              <div class="tw-space-y-1 tw-font-light">
                <div v-if="data.address.businessName">{{ data.address.businessName }}</div>
                <div v-if="data.address.addressLine1">{{ data.address.addressLine1 }}</div>
                <div v-if="data.address.addressLine2">{{ data.address.addressLine2 }}</div>
                <div>
                  {{ data.delivery.date ? formatDate(data.delivery.date) : '' }} {{ timeForSlot(activeSlotName) }}
                </div>
                <div v-if="data.delivery.instructions">{{ data.delivery.instructions }}</div>
              </div>
            </template>
          </LykaCollapsibleForm>

          <LykaCollapsibleForm class="tw-px-6 tw-pt-6 lg:tw-px-0" submit>
            <template #title>
              <h4 class="tw-h4">Payment</h4>
            </template>

            <template #icons>
              <CheckoutCreditCards />
            </template>

            <template #form>
              <fieldset id="payment-details" class="tw-mb-6 tw-mt-6">
                <StripePayment
                  :payment-method-configuration="paymentMethodConfiguration"
                  :dollars="discountedTotalPrice"
                  @created="onStripePaymentElementCreate"
                  @change="onStripePaymentElementChange"
                />
              </fieldset>
            </template>

            <template #submit>
              <div v-if="errorMessage" class="tw-mb-4">
                <LykaError>
                  {{ errorMessage }}
                </LykaError>
              </div>

              <CheckoutSubmitButton :submitting="submitting" :disabled="disabled">
                Place my order - {{ formatMoney(discountedTotalPrice) }}
              </CheckoutSubmitButton>

              <CheckoutTerms class="tw-mt-8" />
            </template>
          </LykaCollapsibleForm>
        </LykaCollapsibleFormGroup>
      </template>

      <template #summary>
        <template v-if="mealPlan">
          <CheckoutOrderSummaryRedesign
            v-if="showOrderSummaryRedesign"
            :meal-plan="mealPlan"
            :meal-plans="allMealPlans"
            :meal-weights="mealWeights"
            :discount="discount"
            :every-box="treatsInEveryBox"
            :treat-quantities="treatQuantities"
            :total-price="totalPrice"
            :discounted-total-price="discountedTotalPrice"
            :recipes="recipes"
          >
            <template #coupon>
              <CheckoutRedesignCoupon
                :email="data.user.email"
                :coupon="appliedCoupon"
                :meal-plan-type="mealPlan.type"
                @change="updateCoupon"
                @remove="removeCoupon"
              />
            </template>
          </CheckoutOrderSummaryRedesign>

          <template v-else>
            <div class="tw-px-6 lg:tw-px-0">
              <CheckoutOrderSummary
                :meal-plan="mealPlan"
                :meal-weights="mealWeights"
                :discount="discount"
                :every-box="treatsInEveryBox"
                :treat-quantities="treatQuantities"
              />
            </div>

            <CheckoutSeparator class="tw-border-dashed" />

            <div class="tw-px-6 lg:tw-px-0">
              <CheckoutCoupon
                :email="data.user.email"
                :coupon="appliedCoupon"
                :meal-plan-type="mealPlan.type"
                @change="updateCoupon"
                @remove="removeCoupon"
              >
                <template #title>Promo/Referral Code</template>
              </CheckoutCoupon>
            </div>

            <CheckoutSeparator class="tw-border-dashed" />

            <div class="tw-px-6 lg:tw-px-0">
              <CheckoutPriceSummary :total-price="totalPrice" :discounted-total-price="discountedTotalPrice" />
            </div>

            <CheckoutSeparator />

            <div class="tw-px-6 lg:tw-px-0 tw-py-4 tw-flex tw-flex-col tw-gap-8">
              <CheckoutInfo
                title="Change or cancel your plan anytime"
                text="We'll send a reminder before we take payment for your next order."
              />
            </div>

            <CheckoutSeparator class="md:tw-hidden" />
          </template>
        </template>
      </template>

      <template #reviews>
        <TrustpilotReviews class="tw-px-2 tw-mt-2" :height="40" template="5419b6ffb0d04a076446a9af" />
      </template>
    </CheckoutLayout>

    <Teleport to="#app-footer">
      <CheckoutFooter />
    </Teleport>
  </LykaContainer>
</template>

import { useSegment } from '@lyka/vue-common/composables/useSegment'
import snakecaseKeys from 'snakecase-keys'
import type { EventSender } from '../EventSender'
import { prefixKeys } from '@/utils/prefixKeys'

interface Flags {
  known?: boolean
}

export const useExperimentEvents: EventSender = (experiments: Record<string, string>, flags?: Flags) => {
  const send = (): void => {
    // Identify the user with the new experiments
    useSegment().identify(snakecaseKeys(prefixKeys(experiments, 'experiment-'), { deep: true }))

    // Fire an event for each experiment the user has (this is for Mixpanel)
    Object.entries(experiments).forEach(([experimentName, experimentVariant]) => {
      useSegment().track('Experiment Started', {
        known: flags?.known,
        experimentName,
        experimentVariant,
      })
    })
  }

  return {
    send,
  }
}

export const useExperimentForcedEvents: EventSender = (experiments: Record<string, string>) => {
  const send = (): void => {
    Object.entries(experiments).forEach(([experimentName, experimentVariant]) => {
      useSegment().track('Experiment Forced', {
        experimentName,
        experimentVariant,
      })
    })
  }

  return {
    send,
  }
}

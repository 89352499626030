<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { assetUrl } from '@lyka/utils'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const image = ref<HTMLImageElement | null>(null)

const src = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return assetUrl('images/steps/plans/bowls/half.webp')
    default:
      return assetUrl('images/steps/plans/bowls/full.webp')
  }
})

const alt = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
    case MealPlanType.Starter:
    case MealPlanType.Starter1Week:
      return 'Full Bowl'
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return 'Half Bowl'
    case MealPlanType.Trial:
    case MealPlanType.Trial7x200g:
    case MealPlanType.Trial14x200g:
      return 'Trial Box'
    default:
      return props.mealPlan.type satisfies never
  }
})

const animateImage = async (image: HTMLImageElement): Promise<void> => {
  image.hidden = true

  if (image.loading) {
    await new Promise((resolve) => image.addEventListener('load', resolve, { once: true }))
  }

  requestAnimationFrame(() => {
    image.hidden = false
  })
}

const mo = new MutationObserver(async ([mutation]) => {
  if (mutation?.target instanceof HTMLImageElement && mutation.attributeName === 'src') {
    const image = mutation.target as HTMLImageElement

    animateImage(image)
  }
})

onMounted(() => {
  if (image.value) {
    mo.observe(image.value, { attributes: true })
  }
})
</script>

<template>
  <div
    class="tw-absolute tw-left-0 -tw-translate-x-1/2 tw-top-0 md:tw-top-1/2 md:-tw-translate-y-1/2 md:-tw-translate-x-1/3"
  >
    <div class="tw-rotate-90 md:tw-rotate-0">
      <img
        v-if="src"
        ref="image"
        :src="src"
        :alt="alt"
        class="tw-w-40 md:tw-w-44 lg:tw-w-56 tw-h-auto tw-animate-slide-right"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { MealPlanTypeTrials } from '../../../models/MealPlan'
import PlanInfoCaloriesModal from './PlanInfoCaloriesModal.vue'
import PlanInfoTrialModal from './PlanInfoTrialModal.vue'
import { type MealPlanWeight } from '@/models/MealPlanWeight'
import { type MealPlan } from '@/models/MealPlan'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const isTrialPlan = computed<boolean>(() => {
  return MealPlanTypeTrials.includes(props.mealPlan.type)
})
</script>

<template>
  <PlanInfoTrialModal v-if="isTrialPlan" :meal-plan="mealPlan" :meal-weights="mealWeights" @close="emits('close')" />
  <PlanInfoCaloriesModal v-else :meal-plan="mealPlan" :meal-weights="mealWeights" @close="emits('close')" />
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { LykaButton, LykaFieldList, LykaInput } from '@lyka/ui'
import { clone } from 'remeda'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import SignBorder from '../../components/icons/SignBorder.vue'
import MarketingConsentCheckbox from '../MarketingConsentCheckbox.vue'
import { useEmailCaptured } from '@/composables/useEmailCaptured'
import { useNotifyUnservicedLocation } from '@/composables/useNotifyUnservicedLocation'
import { useLocationStep } from '@/steps/location'
import type { UnservicedLocationStepData } from '@/steps/unservicedLocation'
import TickCircleIcon from '@/components/icons/TickCircleIcon.vue'

const props = defineProps<{
  stepData: UnservicedLocationStepData
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const submitting = ref(false)
const submitted = ref(false)

const captureEmail = async (): Promise<void> => {
  if (data.marketingCommunication) {
    return useEmailCaptured().subscribe(data.email, data.firstName)
  }

  return useEmailCaptured().unsubscribe(data.email, data.firstName)
}

const submitNotify = async (): Promise<void> => {
  const postcode = useLocationStep().data.user.postcode

  await useNotifyUnservicedLocation().submit({
    ...data,
    postcode,
  })
}

const submit = async ({ target }: Event): Promise<void> => {
  if (!(target instanceof HTMLFormElement)) {
    return
  }

  if (!target.reportValidity()) {
    return
  }

  submitting.value = true

  try {
    await Promise.all([captureEmail(), submitNotify()])
    useSegment().track('Unserviced Postcode Completed', { postcode: useLocationStep().data.user.postcode })

    submitted.value = true
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <StepContainer v-if="submitted">
    <div class="tw-flex tw-flex-col tw-items-center tw-space-y-8 tw-mb-28 tw-pt-16 sm:tw-pt-32">
      <span class="tw-text-9xl"><TickCircleIcon /></span>

      <h3 class="tw-h1">Thank you!</h3>
      <p>We'll send you an email when we can deliver to your area</p>
    </div>

    <LykaButton block size="lg" href="/"> Return to the Lyka website </LykaButton>
  </StepContainer>

  <StepContainer v-else>
    <div class="tw-space-y-4 sm:tw-space-y-8 tw-py-8">
      <div class="tw-flex tw-items-center tw-justify-center">
        <SignBorder alt="sign displaying the words coming soon" />
      </div>
      <h2 class="tw-h2">Sorry, Lyka isn't available in your area yet</h2>
      <p>
        We're working as fast as we can to share Lyka with puppers all around Australia! Enter your name and email,
        we'll let you know once we're in your neighbourhood.
      </p>
    </div>

    <form id="formUnserviced" @submit.prevent="submit">
      <div class="tw-space-y-8">
        <LykaFieldList>
          <LykaInput
            v-model.trim="data.firstName"
            name="first-name"
            label="First name"
            required
            error-message="Your first name is required"
            autocapitalize
          />
          <LykaInput
            v-model.trim="data.lastName"
            name="last-name"
            label="Last name"
            required
            error-message="Your last name is required"
            autocapitalize
          />
          <LykaInput
            v-model.trim="data.email"
            name="email"
            label="Email"
            type="email"
            required
            error-message="Your email is required"
          />
        </LykaFieldList>

        <MarketingConsentCheckbox v-model="data.marketingCommunication" />
      </div>

      <StepNavigation
        submit
        :next-loading="submitting"
        next-text="Notify me"
        :next-icon="false"
        form="formUnserviced"
        @previous="emits('previous')"
      />
    </form>
  </StepContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import BellIcon from '@/components/icons/BellIcon.vue'
import { MealPlanType } from '@/models/MealPlan'

const props = defineProps<{
  mealPlanType: MealPlanType | null
}>()

const nextMealPlanType = computed<MealPlanType>(() => {
  switch (props.mealPlanType) {
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return MealPlanType.Half
    default:
      return MealPlanType.Full
  }
})

const nextMealPlanName = computed<string | undefined>(() => {
  switch (nextMealPlanType.value) {
    case MealPlanType.Half:
      return 'Half Bowl Plan'
    default:
      return 'Full Bowl Plan'
  }
})
</script>

<template>
  <div class="tw-bg-cream tw-p-8 tw-rounded-2xl tw-space-y-4 tw-max-w-md">
    <h3 class="tw-font-stylized tw-text-4xl">What happens next?</h3>
    <p class="tw-font-semibold">After your Starter Box, you’ll automatically move to a {{ nextMealPlanName }}.</p>
    <p>This means your next delivery will include your dog’s full daily portion size.</p>
    <div class="tw-flex tw-items-center tw-gap-3 tw-border-t tw-border-b tw-border-dashed tw-border-dark/50 tw-py-4">
      <span class="tw-text-4xl">
        <BellIcon />
      </span>
      <p>You’ll get a reminder <strong>before we prepare your next order.</strong></p>
    </div>
    <p class="tw-font-semibold">Skip, change, or cancel deliveries</p>
    <p>Log in to your account to make changes at any time.</p>
  </div>
</template>

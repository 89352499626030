<script setup lang="ts">
import { computed } from 'vue'
import CheckoutOrderSummaryTreatsProduct from './CheckoutOrderSummaryTreatsProduct.vue'

const props = defineProps<{
  everyBox?: boolean
  treatQuantities?: Record<number, number>
}>()

const hasTreats = computed(() => {
  if (!props.treatQuantities) {
    return false
  }

  return Object.values(props.treatQuantities).some((quantity) => !!quantity)
})
</script>

<template>
  <div v-if="hasTreats" id="summary-treats" class="tw-divide-y tw-divide-mint-green">
    <CheckoutOrderSummaryTreatsProduct
      v-for="(quantity, productId) in treatQuantities"
      :key="productId"
      :product-id="Number(productId)"
      :quantity="quantity"
      :every-box="everyBox"
    />
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import { capitalize, computed, ref, watch } from 'vue'
import { stripEmojis } from '@lyka/utils/src/stripEmojis'
import { createElementId } from '../utils/createElementId'
import IconVisibility from './icon/Visibility.vue'
import IconVisibilityHidden from './icon/VisibilityHidden.vue'
import LykaButton from './LykaButton.vue'
import InputError from './InputError.vue'

type ModelValue = string | number | string[] | undefined

const props = withDefaults(
  defineProps<{
    label?: string
    hideLabel?: boolean
    error?: boolean
    errorMessage?: string
    hideError?: boolean
    name: string
    size?: 'sm' | 'md' | 'lg' | 'xl'
    modelValue: ModelValue
    modelModifiers?: object
    type?: InputHTMLAttributes['type']
    inputmode?: InputHTMLAttributes['inputmode']
    textarea?: boolean
    prefix?: string
    suffix?: string
    afterInput?: string
    aboveInput?: string
    contentAuto?: boolean
    contentWidth?: number
    autocapitalize?: boolean
    required?: boolean
    showOptional?: boolean
    passwordVisible?: boolean
    showZero?: boolean
  }>(),
  {
    size: 'md',
    type: 'text',
  },
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
  (e: 'passwordShown'): void
  (e: 'passwordHidden'): void
  (e: 'invalidValue'): void
  (e: 'validValue'): void
  (e: 'change', value: ModelValue): void
}>()

const inputId = createElementId()
const invalid = ref(false)
const dirty = ref(false)
const modified = ref(false)
const validationMessage = ref('')
const inputElement = ref<HTMLInputElement>()

const isNumberField = computed(() => props.type === 'number')

const value = computed({
  get() {
    // Show `zero` as an empty value
    if (isNumberField.value && props.modelValue === 0 && !modified.value && !props.showZero) {
      return ''
    }

    return props.modelValue
  },
  set(value) {
    if (props.autocapitalize && typeof value === 'string') {
      value = capitalize(value)
    }

    // Convert value to number
    if (isNumberField.value) {
      value = Number(value)
    } else if (typeof value === 'string') {
      value = stripEmojis(value)
    }

    emits('update:modelValue', value)
  },
})

const inputHeight = computed(() => {
  switch (props.size) {
    case 'sm': {
      return 'tw-global-height--small'
    }
    case 'lg': {
      return 'tw-global-height--large'
    }
    case 'xl': {
      return 'tw-global-height--xlarge'
    }
    default: {
      return 'tw-global-height'
    }
  }
})

const isInput = (target: EventTarget | null): target is HTMLInputElement | HTMLTextAreaElement =>
  target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement

const getValidity = (element: HTMLInputElement | HTMLTextAreaElement): boolean => {
  // If the field isn't required and has no value then it's valid
  if (!props.required && !element.value) {
    return true
  }

  return element.checkValidity()
}

const checkValidity = (element: HTMLInputElement | HTMLTextAreaElement): void => {
  element.setCustomValidity('')

  const valid = getValidity(element)

  valid ? emits('validValue') : emits('invalidValue')

  invalid.value = !valid
}

// If the required property on the field is changed then revalidate it
watch(
  () => props.required,
  () => {
    if (inputElement.value) {
      checkValidity(inputElement.value)
    }
  },
)

const getValidationMessage = (element: HTMLInputElement | HTMLTextAreaElement): string => {
  const state = element.validity

  if (state.valueMissing) {
    element.setCustomValidity('Field is required')
  } else if (state.typeMismatch && element.type === 'email') {
    element.setCustomValidity('Please enter a valid email')
  }

  return element.validationMessage
}

const onInvalid = ({ target }: Event): void => {
  invalid.value = true

  if (isInput(target)) {
    validationMessage.value = getValidationMessage(target)
  }
}

const onBlur = ({ target }: Event): void => {
  dirty.value = true

  if (isInput(target)) {
    checkValidity(target)
  }
}

const onInput = ({ target }: Event): void => {
  modified.value = true

  if ((dirty.value || invalid.value) && isInput(target)) {
    checkValidity(target)
  }
}

const onChange = ({ target }: Event): void => {
  if (isInput(target)) {
    emits('change', target.value)
  }
}

const showError = computed(() => {
  return (props.error || invalid.value) && !props.hideError
})

const passwordShown = ref(false)

const inputType = computed<InputHTMLAttributes['type']>(() => {
  if (passwordShown.value) {
    return 'text'
  }

  return props.type
})

const isPasswordField = computed(() => {
  return props.type === 'password'
})

const togglePasswordVisibility = (): void => {
  passwordShown.value = !passwordShown.value

  if (passwordShown.value) {
    emits('passwordShown')
  } else {
    emits('passwordHidden')
  }
}

const isPasswordVisible = computed(() => {
  return passwordShown.value
})

watch(
  () => props.passwordVisible,
  (value) => {
    if (value !== undefined) {
      passwordShown.value = value
    }
  },
  { immediate: true },
)
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-space-y-2 tw-input-wrap tw-text-alt">
    <div class="tw-flex tw-gap-3 tw-justify-between">
      <label v-if="label" class="tw-input-label" :class="{ 'tw-sr-only': hideLabel }" :for="inputId">{{ label }}</label>
      <span
        v-if="!required && showOptional"
        class="tw-text-opacity-50 tw-text-sm tw-text-dark tw-ml-auto"
        :class="{ 'tw-sr-only': hideLabel }"
      >
        (optional)
      </span>
    </div>

    <div
      class="tw-relative tw-input-row tw-px-4 sm:tw-px-5"
      :data-invalid="invalid"
      :class="{
        'tw-px-4 sm:tw-px-5': !suffix || !$slots.suffix,
        'tw-pl-4 sm:tw-pl-5': suffix || $slots.suffix,
        'tw-rounded-full': !textarea && !(aboveInput || $slots.aboveInput),
        'tw-rounded-3xl': textarea || aboveInput || $slots.aboveInput,
        'tw-pt-2': aboveInput || $slots.aboveInput,
      }"
    >
      <div
        v-if="aboveInput || $slots.aboveInput"
        class="tw-absolute tw-bg-mint-green tw-bg-opacity-60 tw-top-1.5 tw-rounded-lg tw-p-1 tw-px-1.5 tw-text-xs"
      >
        {{ aboveInput }}
        <slot name="aboveInput" />
      </div>
      <div v-if="prefix || $slots.prefix">
        {{ prefix }}
        <slot name="prefix" />
      </div>
      <textarea
        v-if="textarea"
        :id="inputId"
        ref="inputElement"
        v-model="value"
        :name="name"
        class="tw-input tw-py-2"
        :class="{
          'tw-pl-2': prefix || $slots.prefix,
          'tw-pr-2': suffix || $slots.suffix,
          'tw-w-auto': contentAuto,
          'tw-w-full': !contentWidth && !contentAuto,
        }"
        :style="{ width: contentWidth ? `${contentWidth}px` : undefined }"
        v-bind="$attrs"
        rows="6"
        :required="required"
        @input="onInput"
        @blur="onBlur"
        @change="onChange"
        @invalid.prevent="onInvalid"
      />
      <input
        v-else
        :id="inputId"
        ref="inputElement"
        v-model="value"
        :name="name"
        class="tw-input"
        :class="[
          inputHeight,
          {
            'tw-pl-2': prefix || $slots.prefix,
            'tw-pr-2': suffix || $slots.suffix,
            'tw-w-auto': contentAuto,
            'tw-w-full': !contentWidth && !contentAuto,
            'tw-pt-4': aboveInput || $slots.aboveInput,
          },
        ]"
        :style="{ width: contentWidth ? `${contentWidth}px` : undefined }"
        v-bind="$attrs"
        :type="inputType"
        :inputmode="inputmode"
        :required="required"
        @input="onInput"
        @blur="onBlur"
        @change="onChange"
        @invalid.prevent="onInvalid"
      />

      <div
        v-if="afterInput || $slots.afterInput"
        class="tw-flex-auto"
        :class="{
          'tw-pt-4': aboveInput || $slots.aboveInput,
        }"
      >
        {{ afterInput }}
        <slot name="afterInput"></slot>
      </div>

      <div
        v-if="suffix || $slots.suffix"
        :class="{
          '-tw-mt-2': aboveInput || $slots.aboveInput,
        }"
      >
        {{ suffix }}
        <slot name="suffix" />
      </div>

      <div v-if="isPasswordField" aria-hidden="true" class="-tw-mr-4">
        <LykaButton
          size="sm"
          square
          variant="subtle"
          :sr-only="isPasswordVisible ? 'Hide password' : 'Show password'"
          transparent
          @click="togglePasswordVisibility"
        >
          <template #iconLeft>
            <IconVisibilityHidden v-if="isPasswordVisible" />
            <IconVisibility v-else />
          </template>
        </LykaButton>
      </div>

      <div v-if="$slots.button" class="-tw-mr-4">
        <slot name="button" />
      </div>

      <slot />
    </div>

    <InputError v-if="showError" :error="errorMessage || validationMessage" />
  </div>
</template>

<style lang="postcss">
.tw-input-label {
  @apply tw-text-left tw-text-alt;
}
.tw-input-row {
  @apply tw-border tw-border-gold tw-flex tw-bg-white tw-items-center tw-text-base hover:tw-bg-cream tw-transition data-[invalid="true"]:tw-border-danger;
}
.tw-input-wrap:focus-within .tw-input-row {
  @apply tw-border-success hover:tw-bg-white tw-shadow-[0_0_0_4px_#DBE6DC];
}
.tw-input {
  @apply tw-bg-transparent tw-outline-none tw-placeholder-gold;
}
</style>

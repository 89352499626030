<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import { computed } from 'vue'
import RightChevronIcon from '@/components/icons/RightChevronIcon.vue'
import type { PostCheckoutClick } from '@/events/checkoutCompleted'
import { useCoupons } from '@/composables/useCoupons'
import { useStateStore } from '@/stores/state'
import env from '@/env'

const props = defineProps<{
  image: string
}>()

const emits = defineEmits<{
  (e: 'click', value: PostCheckoutClick): void
}>()

const goToDashboard = (): void => {
  emits('click', {
    cta: 'continue to dashboard',
    path: '/membership/dashboard',
    url: env.VITE_CUSTOMER_PORTAL_URL,
  })
}

const showResetButton = computed<boolean>(() => {
  return useCoupons().inPersonSalesCoupon.value
})

const reset = (): void => {
  // eslint-disable-next-line no-alert
  const confirmed = confirm('Are you sure you want to reset all of your data?')

  if (!confirmed) {
    return
  }

  useStateStore().resetState()
  location.reload()
}
</script>

<template>
  <div class="tw-relative">
    <div class="tw-flex-col-reverse tw-flex lg:tw-flex-row lg:tw-min-h-screen tw-text-alt">
      <!-- Left hand side content -->
      <div class="tw-flex lg:tw-w-2/3 xl:tw-w-1/2 tw-justify-center tw-my-12 lg:tw-my-32 tw-mb-6">
        <div class="tw-w-11/12 lg:tw-w-2/3 tw-space-y-6 tw-text-center lg:tw-text-start">
          <div>
            <a href="/">
              <img class="tw-w-20 tw-mx-auto lg:tw-mx-0" alt="lyka-logo" src="@/assets/images/logo-green.svg" />
            </a>
          </div>

          <slot name="content" />

          <div>
            <LykaButton
              id="checkout-continue"
              name="been-here-before-button"
              text-link
              variant="success"
              @click="goToDashboard"
            >
              Continue to the dashboard
              <template #iconRight>
                <RightChevronIcon />
              </template>
            </LykaButton>
          </div>

          <div>
            <LykaButton v-if="showResetButton" size="sm" variant="alt" type="button" @click="reset"> Reset</LykaButton>
          </div>
        </div>
      </div>

      <!-- Right hand side image -->
      <div
        class="tw-bg-scroll tw-w-1/3 xl:tw-w-1/2 tw-bg-cover tw-hidden lg:tw-block tw-bg-center"
        :style="{ backgroundImage: `url(${props.image})` }"
      />
    </div>
  </div>
</template>

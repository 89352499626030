<script setup lang="ts">
import { computed } from 'vue'
import CheckoutOrderSummaryRedesignTreat from './CheckoutOrderSummaryRedesignTreat.vue'
import CheckoutOrderSummaryNextPlan from './CheckoutOrderSummaryNextPlan.vue'
import { type MealPlan, MealPlanType, getMealPlanName, isStarterPlanType, isTrialPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { formatMoney } from '@/utils/formatMoney'
import { useProductPrice } from '@/composables/useProductPrice'
import { type Recipe, isTherapeuticRecipe } from '@/models/Recipe'
import { StepName, useStepsStore } from '@/stores/steps'

const props = defineProps<{
  mealPlan: MealPlan
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
  everyBox?: boolean
  treatQuantities?: Record<number, number>
  discount?: number
  totalPrice: number
  discountedTotalPrice: number
  recipes: Recipe[]
}>()

const GO_TO_GUT_HELPER_PRICE = 16.9

const { getDiscountedPrice } = useProductPrice()

const dogNames = computed<string>(() => {
  const [first, second] = props.mealWeights.map((mealWeight) => mealWeight.name)

  if (second) {
    return 'Your'
  }

  return `${first}'s`
})

const includesGoToGutHelper = computed(() => {
  return !isTrialPlanType(props.mealPlan.type)
})

const includesHypoProGuide = computed<boolean>(() => {
  return !isTrialPlanType(props.mealPlan.type) && !!props.recipes.length && props.recipes.every(isTherapeuticRecipe)
})

const includesGamePlan = computed(() => {
  return !isTrialPlanType(props.mealPlan.type) && !includesHypoProGuide.value
})

const planDescription = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Starter1Week:
    case MealPlanType.StarterHalf1Week:
      return '7 days of Lyka meals'
    case MealPlanType.Starter:
    case MealPlanType.StarterHalf:
      return '14 days of Lyka meals'
    default:
      return getMealPlanName(props.mealPlan.type)
  }
})

const orderPrice = computed(() => {
  return props.mealPlan.totalPrice
})

const discountedOrderPrice = computed<number>(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice, props.mealPlan.type)
})

const discountedPricePerDay = computed<number>(() => {
  return getDiscountedPrice('meals', props.mealPlan.priceDaily, props.mealPlan.type)
})

const recipeNames = computed<string>(() => {
  const recipes = props.recipes.map((r) => r.displayText)

  return [...new Set(recipes)].join(', ')
})

const nextMealPlanType = computed<MealPlanType>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return MealPlanType.Half
    default:
      return MealPlanType.Full
  }
})

const nextMealPlan = computed<MealPlan | undefined>(() => {
  return props.mealPlans.find((mealPlan) => mealPlan.type === nextMealPlanType.value)
})

const nextMealPlanDeliveryFrequency = computed<number | undefined>(() => {
  return nextMealPlan.value?.frequency
})

const isStarterPlan = computed<boolean>(() => {
  return isStarterPlanType(props.mealPlan.type)
})

const planName = computed<string>(() => {
  return getMealPlanName(props.mealPlan.type)
})

const goToRecipesStep = (): void => {
  useStepsStore().goToStep(StepName.Recipes)
}

const goToPlanStep = (): void => {
  useStepsStore().goToStep(StepName.Plan)
}

const starterPlanLabel = computed<string | undefined>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Starter:
      return 'Full bowl'
    case MealPlanType.StarterHalf:
      return 'Half bowl'
    default:
      return undefined
  }
})

const starterPlanDuration = computed<number>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Starter:
    case MealPlanType.StarterHalf:
      return 14
    case MealPlanType.Starter1Week:
    case MealPlanType.StarterHalf1Week:
      return 7
    default:
      return 0
  }
})
</script>

<template>
  <div class="tw-space-y-8 tw-mb-8 tw-px-6 md:tw-px-0">
    <section>
      <h3 class="tw-h4 tw-mb-4">Order Summary</h3>

      <div class="tw-bg-white tw-border-mint-green tw-border tw-rounded-2xl">
        <header
          class="tw-py-5 tw-px-6 tw-border-b tw-border-dashed tw-border-mint-green tw-flex tw-items-center tw-gap-4 tw-justify-between"
        >
          <div class="tw-space-y-1">
            <h4 class="tw-h4">{{ dogNames }} {{ planName.toLowerCase() }}</h4>
            <div v-if="starterPlanLabel" class="tw-text-sm tw-font-light">{{ starterPlanLabel }}</div>
          </div>
          <div>
            <button class="tw-text-green" @click="goToPlanStep">Edit</button>
          </div>
        </header>
        <div>
          <table class="summary">
            <tbody>
              <!-- Plan -->
              <tr>
                <th>
                  <div>{{ planDescription }}</div>

                  <div class="summary-description">
                    {{ recipeNames }} at {{ formatMoney(discountedPricePerDay) }}/day
                  </div>

                  <div class="summary-description">
                    <button class="tw-text-green" @click="goToRecipesStep">Edit</button>
                  </div>
                </th>
                <td class="summary-price">
                  <s class="summary-discounted">{{ formatMoney(orderPrice) }}</s>
                  <span>{{ formatMoney(discountedOrderPrice) }}</span>
                </td>
              </tr>

              <!-- Treats -->
              <CheckoutOrderSummaryRedesignTreat
                v-for="(quantity, productId) in treatQuantities"
                :key="productId"
                :quantity="quantity"
                :product-id="Number(productId)"
              />

              <!-- Go-To Gut Helper -->
              <tr v-if="includesGoToGutHelper">
                <th>
                  Go-To Gut Helper
                  <div class="summary-description">Normally {{ formatMoney(GO_TO_GUT_HELPER_PRICE) }}</div>
                </th>

                <td class="summary-price">
                  <span>Free</span>
                </td>
              </tr>

              <!-- Hypo-pro guide -->
              <tr v-if="includesHypoProGuide">
                <th>Hypoallergenic Pro Guide</th>
                <td class="summary-price">
                  <span>Free</span>
                </td>
              </tr>

              <!-- Game plan -->
              <tr v-if="includesGamePlan">
                <th>Transition Game Plan</th>
                <td class="summary-price">
                  <span>Free</span>
                </td>
              </tr>

              <!-- Delivery -->
              <tr>
                <th>
                  <div>Delivery</div>
                  <div class="summary-description">Delivered frozen for freshness</div>
                </th>
                <td class="summary-price">Included</td>
              </tr>

              <!-- Discount -->
              <tr>
                <th colspan="2">
                  <slot name="coupon" />
                </th>
              </tr>
            </tbody>

            <!-- Total -->
            <tfoot>
              <tr>
                <th>Today's order total</th>
                <td class="summary-price">
                  {{ formatMoney(discountedTotalPrice) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>

    <div v-if="isStarterPlan && nextMealPlan">
      <h3 class="tw-h4 tw-mb-4">After {{ dogNames }} starter box</h3>

      <CheckoutOrderSummaryNextPlan :meal-plan="nextMealPlan" />
    </div>

    <section class="tw-p-6 tw-bg-[#EFF3ED] tw-flex tw-gap-4 tw-items-start tw-rounded-xl">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill-rule="evenodd"
        xmlns="http://www.w3.org/2000/svg"
        class="tw-shrink-0"
      >
        <path
          d="M16.77 4.722a.625.625 0 1 0-1.04-.694L8.653 14.644l-4.461-4.46a.625.625 0 1 0-.884.883l5 5a.625.625 0 0 0 .962-.095l7.5-11.25Z"
          fill="#10B193"
        />
      </svg>

      <div class="tw-space-y-2 tw-text-sm">
        <h3 class="tw-font-bold">Pause or cancel at anytime</h3>
        <p class="tw-font-light tw-text-sm">You’ll get a reminder before we take payment for your next order.</p>
      </div>
    </section>
  </div>
</template>

<style scoped>
.summary {
  @apply tw-w-full;
}

.summary th {
  @apply tw-text-left;
}

.summary th,
.summary td {
  @apply tw-px-6 tw-py-3;
}

.summary tr:first-child td,
.summary tr:first-child th {
  @apply tw-pt-6;
}

.summary tr:last-child td,
.summary tr:last-child th {
  @apply tw-pb-6;
}

.summary tfoot {
  @apply tw-border-t tw-border-dashed tw-border-mint-green;
}

.summary td {
  @apply tw-text-right;
}

.summary-discounted {
  @apply tw-text-sm tw-text-opacity-40 tw-text-dark;
}

.summary-price {
  @apply tw-text-right tw-whitespace-nowrap tw-items-center tw-gap-1.5 tw-flex tw-justify-end tw-content-center;
}

.summary-description {
  @apply tw-pt-1 tw-font-light tw-text-sm;
}
</style>

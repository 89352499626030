import { assetUrl } from '@lyka/utils'
import type { Product } from './Product'

export interface Treat {
  id: number
  name: string
  displayName: string
  treatDetails: TreatDetails[]
  products: Product[]
  skuSize: string
  aboutDescription: string // Warning: contains HTML
  images: TreatImage[]
  limitedStock: boolean
  active: boolean
  activeCustomer: boolean
}

// The current treats that are displayed as 'new'
export const NEW_TREAT_NAMES: ReadonlyArray<string> = ['piggy_training_perks', 'turkey_dental_jerky']

export interface TreatImage {
  imageUrl: string
  isVideo: 0 | 1
}

export interface TreatDetails {
  id: number
  treatBabAboutDescription: string
}

export interface TreatHealthBenefit {
  id: number
  name: string
  imageUrl: string // Just contains the filename of the icon (eg. `tooth.svg`)
  description: string
}

export const getTreatImageURL = (treat: Treat): string | undefined => {
  return assetUrl(`images/treats/${treat.name}/hero.webp`)
}

export const getTreatVideoURL = (treat: Treat): string | undefined => {
  const videoPath = treat.images.find(({ isVideo }) => !!isVideo)?.imageUrl

  if (videoPath) {
    return new URL(videoPath, 'http://player.vimeo.com/').toString()
  }

  return undefined
}

export const getTreatPrice = (treat: Treat): number => {
  return treat.products[0]?.price ?? 0
}

export const getTotalTreatsPrice = (products: Product[], quantities: Record<number, number>): number => {
  let total = 0

  for (const [id, quantity] of Object.entries(quantities)) {
    const product = products.find((product) => product.id === parseInt(id))

    if (product) {
      total += product.price * quantity
    }
  }

  return total
}

export const getTreatHealthBenefits = (treat: Treat): TreatHealthBenefit[] => {
  const [product] = treat.products

  if (product) {
    return product.healthBenefitsTreats
  }

  return []
}

export const isNewTreat = (treat: Treat): boolean => {
  return NEW_TREAT_NAMES.includes(treat.name)
}

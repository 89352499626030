<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'

const tiles = [
  {
    image: assetUrl('images/checkout-completed/joint-supplement.svg'),
    description: 'Amplify your Lyka meals for extra joint and tissue support.',
    buttonLabel: 'Try Joint Supps',
    buttonVariant: 'primary',
    href: '/account/supplements#supps_joint_box',
  },
  {
    image: assetUrl('images/checkout-completed/refur-a-friend.svg'),
    description: 'Refer a friend and enjoy 50% off for each pal that signs up!',
    buttonLabel: 'Learn more',
    buttonVariant: 'secondary',
    href: '/membership/refer',
  },
] as const
</script>

<template>
  <div class="tw-space-y-4 tw-max-w-md">
    <h3 class="tw-font-stylized tw-text-4xl tw-text-center md:tw-text-left">More from Lyka</h3>
    <div class="tw-grid tw-grid-cols-1 tw-gap-6 tw-items-stretch">
      <div
        v-for="(tile, index) in tiles"
        :key="index"
        class="tw-bg-cream tw-rounded-xl tw-gap-4 md:tw-flex tw-items-center tw-text-center md:tw-text-left"
      >
        <div class="tw-flex tw-justify-center tw-pt-6 md:tw-pt-0 md:tw-h-full md:tw-order-last">
          <img
            class="md:tw-h-full tw-h-32 md:tw-w-72 tw-object-contain tw-object-right-bottom"
            :src="tile.image"
            alt=""
            aria-hidden="true"
          />
        </div>
        <div class="tw-space-y-4 tw-p-6">
          <p>{{ tile.description }}</p>
          <LykaButton :href="tile.href" :variant="tile.buttonVariant">{{ tile.buttonLabel }}</LykaButton>
        </div>
      </div>
    </div>
  </div>
</template>

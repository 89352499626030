<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="tw-w-[1em] tw-h-[1em] tw-fill-current"
  >
    <path
      d="M12.2484 2.25H16.05C16.3453 2.25 16.6172 2.42344 16.7344 2.69531L18.2062 6H13.5L12.2484 2.25ZM8.74688 2.25L7.5 6H2.79375L4.26562 2.69531C4.3875 2.42344 4.65469 2.25 4.95 2.25H8.75156H8.74688ZM7.5 8.25V10.5C7.5 11.3297 8.17031 12 9 12H12C12.8297 12 13.5 11.3297 13.5 10.5V8.25H18.75V18C18.75 18.4125 18.4125 18.75 18 18.75H3C2.5875 18.75 2.25 18.4125 2.25 18V8.25H7.5ZM18.7922 1.78125C18.3094 0.698437 17.2359 0 16.05 0H4.95C3.76406 0 2.69062 0.698437 2.20781 1.78125L0.257812 6.16875C0.0890625 6.55313 0 6.96563 0 7.3875V18C0 19.6547 1.34531 21 3 21H18C19.6547 21 21 19.6547 21 18V7.3875C21 6.96563 20.9109 6.55313 20.7422 6.16875L18.7922 1.78125Z"
    />
  </svg>
</template>

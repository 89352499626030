<script setup lang="ts">
import { MealPlanType } from '@/models/MealPlan'
import type { MealPlan } from '@/models/MealPlan'

const props = defineProps<{
  selectedMealPlanType: MealPlanType | null
  mealPlans: MealPlan[]
}>()

const emits = defineEmits<{
  (e: 'select', mealPlan: MealPlan): void
}>()

const getMealPlanName = (mealPlan: MealPlan): string => {
  switch (mealPlan.type) {
    case MealPlanType.Full:
    case MealPlanType.Starter:
    case MealPlanType.Starter1Week:
      return 'All Lyka'
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return 'Mix feed'
    case MealPlanType.Trial:
    case MealPlanType.Trial7x200g:
    case MealPlanType.Trial14x200g:
      return 'Trial box'
    default:
      return mealPlan.type satisfies never
  }
}

const isSelected = (mealPlan: MealPlan): boolean => {
  return mealPlan.type === props.selectedMealPlanType
}
</script>

<template>
  <ul role="tablist" class="tw-flex tw-max-w-xl tw-mx-auto tw-gap-2">
    <li v-for="mealPlan in mealPlans" :key="mealPlan.type" class="tw-flex-1">
      <button
        class="tw-group tw-py-4 tw-px-6 tw-text-sm md:tw-text-base aria-selected:tw-bg-mint-green tw-rounded-t-xl tw-w-full tw-pb-10 -tw-mb-6 tw-transition-colors tw-flex tw-place-content-center tw-gap-2 tw-bg-cream tw-border-mint-green tw-border tw-items-center"
        role="tab"
        :aria-selected="isSelected(mealPlan)"
        @click="() => emits('select', mealPlan)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-opacity-0 group-aria-selected:tw-visible tw-w-0 group-aria-selected:tw-w-auto group-aria-selected:tw-opacity-100 tw-transition-all"
        >
          <path
            d="m11.1 13.8-2.15-2.15a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.517.275.7l2.85 2.85c.2.2.433.3.7.3.267 0 .5-.1.7-.3l5.65-5.65a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275L11.1 13.8Zm1.4 8.2a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2.5 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12.5 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22.5 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 0 1-3.9.788Z"
            fill="#10B193"
          />
        </svg>
        <span>{{ getMealPlanName(mealPlan) }}</span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaContainer } from '@lyka/ui'
import PlanTherapeuticWarning from '../PlanTherapeuticWarning.vue'
import PlanInfoModal from '../PlanInfoModal.vue'
import PlanStarterExperimentCard from './PlanStarterExperimentCard.vue'
import PlanStarterExperimentTabs from './PlanStarterExperimentTabs.vue'
import type { MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  selectedMealPlanType: MealPlanType | null
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
  therapeutic?: boolean
}>()

const emits = defineEmits<{
  (e: 'select', mealPlan: MealPlan): void
}>()

const { dollarDiscountCouponAttempt } = useCoupons()

const hasMealPlans = computed(() => {
  return !!props.mealPlans.length
})

const selectedMealPlan = computed<MealPlan | undefined>(() => {
  return props.mealPlans.find((mealPlan) => mealPlan.type === props.selectedMealPlanType)
})

const heading = computed<string>(() => {
  if (props.mealPlans.length > 1) {
    return 'Choose your Lyka starter box'
  }

  return 'Your Lyka starter box'
})

const mealPlanInfo = ref<MealPlan>()

const showMealPlanInfo = (mealPlan: MealPlan): void => {
  mealPlanInfo.value = mealPlan
}

const hideMealPlanInfo = (): void => {
  mealPlanInfo.value = undefined
}

const onPlanSelected = (mealPlan: MealPlan): void => {
  emits('select', mealPlan)
}
</script>

<template>
  <div class="tw-space-y-8">
    <h1 class="tw-h3">{{ heading }}</h1>
    <PlanTherapeuticWarning v-if="therapeutic" />

    <div
      v-if="dollarDiscountCouponAttempt"
      class="tw-mx-auto tw-mb-2 tw-text-green-dark tw-bg-secondary tw-py-4 tw-px-10 tw-w-fit"
    >
      <strong>Your discount is applied at checkout</strong>
    </div>

    <div>
      <div v-if="hasMealPlans">
        <!-- Only show tabs if we have multiple plans -->
        <PlanStarterExperimentTabs
          v-if="mealPlans.length > 1"
          :selected-meal-plan-type="selectedMealPlanType"
          :meal-plans="mealPlans"
          @select="onPlanSelected"
        />

        <PlanStarterExperimentCard v-if="selectedMealPlan" :meal-plan="selectedMealPlan" @info="showMealPlanInfo" />
      </div>

      <LykaContainer v-else size="md">
        <h4 class="tw-lead">
          We're sorry. We had some trouble loading your plan. Please try refreshing your browser or contact support.
        </h4>
      </LykaContainer>
    </div>

    <PlanInfoModal
      v-if="mealPlanInfo"
      :meal-plan="mealPlanInfo"
      :meal-weights="mealWeights"
      @close="hideMealPlanInfo"
    />
  </div>
</template>

const isMobile = (): boolean => document.body.clientWidth < 768

// https://developer.zendesk.com/api-reference/widget-messaging/web/core/

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useZendesk = () => {
  const init = (): void => {
    // Hide the zendesk chat widget on mobile when closed to prevent the widget from obstructing the screen
    if (isMobile()) {
      window.zE?.('messenger', 'hide')

      window.zE?.('messenger:on', 'close', () => {
        window.zE?.('messenger', 'hide')
      })
    }
  }

  const show = (): void => {
    // First show the messenger
    window.zE?.('messenger', 'show')

    // Then open it
    window.zE?.('messenger', 'open')
  }

  const hide = (): void => {
    window.zE?.('messenger', 'hide')
  }

  return {
    init,
    show,
    hide,
  }
}

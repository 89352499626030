<script setup lang="ts">
import { assetUrl } from '@lyka/utils'
import { computed } from 'vue'

const props = defineProps<{
  image: string
  alt: string
  title: string
  description: string
}>()

const src = computed<string>(() => {
  return assetUrl(props.image)
})
</script>

<template>
  <div class="tw-space-y-8 tw-max-w-lg lg:tw-max-w-sm tw-justify-self-center">
    <img :src="src" :alt="alt" class="tw-aspect-video tw-rounded-2xl tw-bg-neutral tw-object-cover tw-w-full" />
    <div class="tw-space-y-4">
      <h3 class="tw-h3">{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<style scoped></style>

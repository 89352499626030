export const discountedPrice = (
  price: number,
  discountAmount = 0,
  discountAmountType: 'percentage' | 'dollar',
): number => {
  if (discountAmountType === 'dollar') {
    return Math.max(price - discountAmount, 0)
  }

  return price * (1 - discountAmount / 100)
}

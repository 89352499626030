<script setup lang="ts">
import { LykaModal } from '@lyka/ui'
import { computed, onMounted } from 'vue'
import { type MealPlanWeight, caloriesFromGrams } from '@/models/MealPlanWeight'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import { usePlanCaloriesModalShownEvent } from '@/composables/events/segment/usePlanCaloriesModalShownEvent'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const isOversizedStarter = computed<boolean>(() => {
  return props.mealPlan.type === MealPlanType.Starter && props.mealPlan.frequency === 1
})

const percentOfCalories = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return '100%'
    case MealPlanType.Half:
      return '50%'
    case MealPlanType.Starter:
      return isOversizedStarter.value ? '50%' : '100%'
    default:
      return ''
  }
})

const caloriesPerDay = (mealPlanWeight: MealPlanWeight): number => {
  const calories = caloriesFromGrams(mealPlanWeight.servingWeight)

  if (props.mealPlan.type === MealPlanType.Half) {
    return Math.round(calories / 2)
  }

  return calories
}

const gramsPerDay = (mealPlanWeight: MealPlanWeight): number => {
  if (props.mealPlan.type === MealPlanType.Half) {
    return Math.round(mealPlanWeight.servingWeight / 2)
  }

  return mealPlanWeight.servingWeight
}

onMounted(() => {
  usePlanCaloriesModalShownEvent().send({
    mealPlan: props.mealPlan,
  })
})
</script>

<template>
  <LykaModal open closable @close="emits('close')">
    <div class="tw-space-y-6 tw-font-light">
      <h3 class="tw-h3 tw-text-center">Daily intake</h3>

      <div class="tw-text-center tw-font-bold tw-text-lg">{{ percentOfCalories }} of their total daily intake:</div>

      <div class="tw-border-mint-green-dark tw-border-y tw-divide-y tw-divide-mint-green-dark">
        <div v-for="mealWeight in mealWeights" :key="mealWeight.key" class="tw-text-center tw-py-4">
          <div class="tw-font-bold tw-text-center tw-mb-4">{{ mealWeight.name }}:</div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-10 sm:tw-gap-6 tw-items-center tw-px-8 md:tw-px-20">
            <div class="tw-grow">
              <div class="tw-font-stylized tw-text-lg md:tw-text-2xl">{{ caloriesPerDay(mealWeight) }}kcal</div>
              <div class="tw-text-xs sm:tw-text-sm">Estimated energy</div>
            </div>

            <div class="tw-text-xl tw-font-bold">=</div>

            <div class="tw-grow">
              <div class="tw-font-stylized tw-text-lg md:tw-text-2xl">{{ gramsPerDay(mealWeight) }}g</div>
              <div class="tw-text-xs sm:tw-text-sm">Lyka per day</div>
            </div>
          </div>
        </div>
      </div>

      <p class="tw-text-sm">
        Daily intake is estimated based on breed, age, weight, and activity levels to meet their ideal weight
        requirements.
      </p>

      <div class="tw-space-y-4">
        <h4 class="tw-text-base tw-font-normal">Did you know?</h4>
        <p class="tw-text-sm">
          Lyka servings are larger than dry foods because the higher moisture content of fresh food is naturally
          retained through gently cooking.
        </p>
      </div>

      <div class="tw-space-y-4">
        <h4 class="tw-text-base tw-font-normal">Still growing?</h4>
        <p class="tw-text-sm">
          We monitor your dogs’ needs as they age to ensure they receive the perfect amount of food.
        </p>
      </div>
    </div>
  </LykaModal>
</template>

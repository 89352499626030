<script setup lang="ts">
import { useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { onMounted } from 'vue'
import CheckoutCompletedBase from './CheckoutCompletedBase.vue'
import CheckoutCompletedExperiment from './CheckoutCompletedExperiment.vue'

const redesignEnabled = useFlagsmith().isEnabled('feature-checkout-completed-redesign')

// Scroll to the top of the page when the component is mounted
const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

onMounted(scrollToTop)
</script>

<template>
  <CheckoutCompletedExperiment v-if="redesignEnabled" />
  <CheckoutCompletedBase v-else />
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { type Dog } from '@/models/Dog'

import TickIcon from '@/components/icons/TickIcon.vue'
import DogIcon from '@/components/icons/DogIcon.vue'
import RightChevronIcon from '@/components/icons/RightChevronIcon.vue'

const props = defineProps<{
  dogs: Dog[]
  dogIndex: number
  dogViews: boolean[]
}>()

const emit = defineEmits<{
  (e: 'dogSwitch', dogIndex: number): void
}>()

const indicatorRef = ref<HTMLDivElement>()
const tabRefs = ref<HTMLDivElement[]>([])
const viewportRef = ref<HTMLDivElement>()
const isNavEnabled = ref<boolean>(false)
const isGoPrevActive = ref<boolean>(false)
const isGoNextActive = ref<boolean>(false)

const isDogCurrent = (index: number): boolean => props.dogIndex === index

const indicateDog = (): void => {
  const tabEl = tabRefs.value[props.dogIndex]
  const indicatorEl = indicatorRef.value

  if (!tabEl) {
    return
  }

  const { width, x } = tabEl.getBoundingClientRect()
  const { x: parentX = 0 } = tabEl.parentElement?.getBoundingClientRect() ?? {}

  if (indicatorEl) {
    indicatorEl.style.left = `${x - parentX + (tabEl.parentElement?.scrollLeft ?? 0)}px`
    indicatorEl.style.width = `${width}px`
  }
}

watch(
  () => props.dogIndex,
  (index) => {
    tabRefs.value[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
    indicateDog()
  },
)

const switchDog = (index: number): void => {
  emit('dogSwitch', index)
}

const activateNavIfNeeded = (): void => {
  const viewportEl = viewportRef.value

  if (!viewportEl) {
    return
  }

  const { width } = viewportEl.getBoundingClientRect()

  isNavEnabled.value = viewportEl.scrollWidth > Math.ceil(width)
  isGoPrevActive.value = viewportEl.scrollLeft > 0
  isGoNextActive.value = viewportEl.scrollWidth > Math.ceil(width) + viewportEl.scrollLeft
}

const goPrev = (): void => {
  props.dogIndex > 0 && emit('dogSwitch', props.dogIndex - 1)
  activateNavIfNeeded()
}

const goNext = (): void => {
  props.dogIndex < props.dogs.length - 1 && emit('dogSwitch', props.dogIndex + 1)
  activateNavIfNeeded()
}

onMounted(() => {
  indicateDog()
  activateNavIfNeeded()

  window.addEventListener('resize', activateNavIfNeeded)
  viewportRef.value?.addEventListener('scroll', activateNavIfNeeded)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', activateNavIfNeeded)
  viewportRef.value?.removeEventListener('scroll', activateNavIfNeeded)
})
</script>

<template>
  <div class="tw-flex tw-gap-2 tw-max-w-full">
    <div
      v-if="isNavEnabled"
      class="tw-flex tw-items-center tw-px-2 tw-cursor-pointer"
      :class="{
        'tw-opacity-30': !isGoPrevActive,
      }"
      @click="goPrev"
    >
      <RightChevronIcon class="tw-w-2 tw-h-3.5 tw-rotate-180" />
    </div>

    <div ref="viewportRef" class="tw-flex tw-h-14 tw-gap-8 tw-grow-1 tw-overflow-hidden tw-relative">
      <div
        ref="indicatorRef"
        class="tw-absolute tw-h-full tw-top-0 tw-border-b-2 tw-border-green-dark tw-transition-all"
      ></div>

      <div
        v-for="(dog, index) in dogs"
        :key="index"
        ref="tabRefs"
        class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer lg:hover:tw-opacity-70"
        :class="[
          `tw-opacity-${isDogCurrent(index) ? 100 : 50}`,
          {
            ' tw-font-bold': isDogCurrent(index),
          },
        ]"
        @click="switchDog(index)"
      >
        <TickIcon v-if="dogViews[index]" class="tw-h-4 tw-w-4" />
        <DogIcon v-else />
        <span>{{ dog.name }}</span>
      </div>
    </div>

    <div
      v-if="isNavEnabled"
      class="tw-flex tw-items-center tw-px-2 tw-cursor-pointer"
      :class="{
        'tw-opacity-30': !isGoNextActive,
      }"
      @click="goNext"
    >
      <RightChevronIcon class="tw-w-2 tw-h-3.5" />
    </div>
  </div>
</template>

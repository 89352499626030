<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaContainer } from '@lyka/ui'
import PlanCard from './PlanCard.vue'
import PlanTherapeuticWarning from './PlanTherapeuticWarning.vue'
import PlanInfoModal from './PlanInfoModal.vue'
import type { MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  selectedMealPlanType: MealPlanType | null
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
  therapeutic?: boolean
}>()

const emits = defineEmits<{
  (e: 'select', mealPlan: MealPlan): void
}>()

const { dollarDiscountCouponAttempt } = useCoupons()

const hasMealPlans = computed(() => {
  return !!props.mealPlans.length
})

const orderedMealPlans = computed<MealPlan[]>(() => {
  return props.mealPlans
})

const mealPlanInfo = ref<MealPlan>()

const showMealPlanInfo = (mealPlan: MealPlan): void => {
  mealPlanInfo.value = mealPlan
}

const hideMealPlanInfo = (): void => {
  mealPlanInfo.value = undefined
}

const onPlanSelected = (mealPlan: MealPlan): void => {
  emits('select', mealPlan)
}

const multipleDogs = computed(() => {
  return props.mealWeights.length > 1
})
</script>

<template>
  <div class="tw-space-y-8">
    <div class="tw-space-y-6">
      <h1 class="tw-h3">Choose how you want to start with Lyka</h1>
      <p v-if="multipleDogs">Prices shown are the total for <strong>all</strong> of your dogs.</p>
    </div>

    <PlanTherapeuticWarning v-if="therapeutic"></PlanTherapeuticWarning>

    <div
      v-if="dollarDiscountCouponAttempt"
      class="tw-mx-auto tw-mb-2 tw-text-green-dark tw-bg-secondary tw-py-4 tw-px-10 tw-w-fit"
    >
      <strong>Your discount is applied at checkout</strong>
    </div>

    <div>
      <div
        v-if="hasMealPlans"
        class="tw-px tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-gap-4 lg:tw-gap-5 xl:tw-gap-6 md:tw-items-stretch md:tw-pt-6"
      >
        <div
          v-for="mealPlan in orderedMealPlans"
          :key="mealPlan.type"
          class="md:tw-w-72 tw-w-full tw-flex tw-justify-center"
        >
          <PlanCard
            :selected="selectedMealPlanType === mealPlan.type"
            :meal-plan="mealPlan"
            :meal-weights="mealWeights"
            @select="onPlanSelected"
            @info="showMealPlanInfo"
          />
        </div>
      </div>

      <LykaContainer v-else size="md">
        <h4 class="tw-lead">
          We're sorry. We had some trouble loading your plan. Please try refreshing your browser or contact support.
        </h4>
      </LykaContainer>
    </div>

    <PlanInfoModal
      v-if="mealPlanInfo"
      :meal-plan="mealPlanInfo"
      :meal-weights="mealWeights"
      @close="hideMealPlanInfo"
    />
  </div>
</template>

import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { SegmentEvents } from '@lyka/vue-common/events/segment'
import type { EventSender } from '../EventSender'
import { useTreatsStore } from '@/stores/treats'
import type { EcommerceDog, EcommerceProduct, SegmentPaymentMethod } from '@/types/events/segment'
import { type CheckoutOrder, getOrderId } from '@/models/CheckoutOrder'
import { getMealPlanName } from '@/models/MealPlan'
import { useUserStore } from '@/stores/user'
import type { PaymentMethod } from '@/composables/useCheckout'

const getEventTreatProducts = (order: CheckoutOrder): EcommerceProduct[] => {
  const treatsStore = useTreatsStore()

  const treats: EcommerceProduct[] = []

  for (const [treatId, treatQuantity] of Object.entries(order.productQuantities)) {
    if (!treatQuantity) {
      continue
    }

    const treat = treatsStore.findProduct(parseInt(treatId))

    if (treat) {
      treats.push({
        productId: treat.id,
        sku: treat.name,
        category: 'Treats',
        name: treat.displayName,
        quantity: treatQuantity,
        price: treat.price,
        subscription: order.productsInEveryBox,
      })
    }
  }

  return treats
}

const getEventPlanProducts = (order: CheckoutOrder): EcommerceProduct[] => {
  if (!order.mealPlan) {
    return []
  }

  return [
    {
      sku: order.mealPlan.type,
      category: 'Meal Plans',
      name: getMealPlanName(order.mealPlan.type),
      price: order.mealPlan.totalPrice,
      quantity: 1,
      subscription: true,
    },
  ]
}

const getEventProducts = (order: CheckoutOrder): Array<EcommerceProduct> => {
  return [...getEventTreatProducts(order), ...getEventPlanProducts(order)]
}

const getEventDogs = (order: CheckoutOrder): Array<EcommerceDog> => {
  const dogs: EcommerceDog[] = []

  order.dogs.forEach((dog) => {
    dogs.push({
      activityLevel: dog.activity.name,
      adultWeight: dog.weight.adult,
      weight: dog.weight.current,
      ageMonths: dog.age.months,
      ageStage: dog.age.is,
      ageYears: dog.age.years,
      bodyShape: dog.bodyShape.name,
      breedPrimary: dog.breed.primary?.name,
      breedSecondary: dog.breed.secondary?.name,
      breedType: dog.breed.type,
      dogName: dog.name,
      fussiness: dog.fusinessLevel.name,
      gender: dog.gender,
      hasHealthIssues: dog.hasIllness,
      hasIntolerances: Object.keys(dog.allergies).length === 0,
      healthIssues: dog.illnesses.map((x) => x.name),
      intolerances: dog.allergies.map((x) => x.name),
      previouslyFed: {
        types: dog.foodTypes ?? [],
        brand: dog.foodBrand ?? null,
      },
      recipes: dog.recipes.map((x) => x.name),
    })
  })

  return dogs
}

const determineSegmentPaymentMethod = (paymentMethod: PaymentMethod): SegmentPaymentMethod => {
  if (paymentMethod === 'apple_pay') {
    return 'Apple Pay'
  }

  if (paymentMethod === 'link') {
    return 'Stripe Link'
  }

  return 'Credit Card'
}

export const getEventData = async ({
  order,
  totalPrice,
  eventId,
  paymentMethod,
}: {
  order: CheckoutOrder
  totalPrice: number
  eventId: string
  paymentMethod: PaymentMethod
}): Promise<SegmentEvents['Order Completed'] | undefined> => {
  const orderId = await getOrderId(order)

  if (!orderId) {
    return
  }

  const products = getEventProducts(order)
  const dogs = getEventDogs(order)
  const userStore = useUserStore()
  const referralCoupon = order.coupon ? !!order.coupon.referrer?.uuid : undefined

  return {
    eventId,
    orderId,
    total: totalPrice,
    discount: order.coupon?.discount ?? 0,
    coupon: order.coupon?.couponCode,
    paymentMethod: determineSegmentPaymentMethod(paymentMethod),
    referralCoupon,
    currency: 'AUD',
    products,
    dogs,
    email: order.user?.email,
    emailOptIn: userStore.user.marketingCommunication,
    firstName: order.user?.email,
    lastName: order.user?.lastName,
    mobile: order.user?.mobile,
    smsOptIn: order.user?.smsOptIn,
    city: order.address?.suburb,
    postcode: order.address?.postcode,
    state: order.address?.state,
    frequency: order.mealPlan?.frequency,
  }
}

export const useOrderCompletedEvent: EventSender = ({
  order,
  totalPrice,
  eventId,
  paymentMethod,
}: {
  order: CheckoutOrder
  totalPrice: number
  eventId: string
  paymentMethod: PaymentMethod
}) => {
  const send = async (): Promise<void> => {
    const eventData = await getEventData({
      order,
      totalPrice,
      eventId,
      paymentMethod,
    })

    if (eventData) {
      useSegment().track('Order Completed', eventData, true)
    }
  }

  return {
    send,
  }
}

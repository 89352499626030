const MIDDAY = 12 as const

// Convert a 24-hour time string (eg. 11:00, 15:00) to a 12 hour time string
export const formatTime = (time: string | number | undefined): string => {
  if (time === undefined) {
    return ''
  }

  let [hours, minutes] = String(time).split(':').map(Number)

  if (hours === undefined) {
    return ''
  }

  const meridiem: 'am' | 'pm' = hours >= 12 ? 'pm' : 'am'

  if (hours === 0) {
    hours = MIDDAY
  }

  if (hours > 12) {
    hours -= MIDDAY
  }

  if (minutes) {
    return `${hours}:${minutes}${meridiem}`
  }

  return `${hours}${meridiem}`
}

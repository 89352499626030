import { computed, ref } from 'vue'
import type { StripeElements } from '@stripe/stripe-js'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { type PendingPurchaseConfirmationResponse, useCheckoutOrder } from './useCheckoutOrder'
import { useCoupons } from './useCoupons'
import { useSentry } from './useSentry'
import { useRecaptcha } from './useRecaptcha'
import { useApi } from './useApi'
import { getTotalTreatsPrice } from '@/models/Treat'
import { type TreatsStepData, useTreatsStep } from '@/steps/treats'
import { usePlanStep } from '@/steps/plan'
import type { MealPlan } from '@/models/MealPlan'
import { useTreatsStore } from '@/stores/treats'
import { type CheckoutStepData, useCheckoutStep } from '@/steps/checkout'
import type { Coupon } from '@/models/Coupon'
import { useMealPlansStore } from '@/stores/mealPlans'
import { useProductPrice } from '@/composables/useProductPrice'
import { useStripe } from '@/services/useStripe'

export type DeliveryData = CheckoutStepData['delivery']
export type AddressData = CheckoutStepData['address']
export type ProductsData = TreatsStepData['products']

// These status codes are fatal and should prevent subsequent checkout attempts
const FATAL_CHECKOUT_STATUS_CODES = [400, 500]

export type PaymentMethod = 'credit_card' | 'apple_pay' | 'link'

const { getDiscountedPrice } = useProductPrice()

export type StripePaymentToken =
  | {
      token: string
    }
  | {
      confirmationToken: string
    }
  | {
      setupIntent: string
    }

export interface SetupIntent {
  id: string
  clientSecret: string
}

export interface OrderCheckoutResult {
  status: 'error' | 'completed' | 'pending_verification'
  data?: PendingPurchaseConfirmationResponse
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckout = () => {
  const { setCoupon, removeActiveCoupon, activeCoupon, referrer } = useCoupons()
  const mealPlanStore = useMealPlansStore()
  const treatsStore = useTreatsStore()
  const mealPlanType = usePlanStep().data.plan.type

  const error = ref<string>()
  const fatalError = ref(false)
  const submitting = ref(false)

  const appliedCoupon = computed(() => activeCoupon.value)

  const discount = computed(() => {
    return appliedCoupon.value?.discount ?? 0
  })

  const treatQuantities = computed<Record<number, number>>(() => {
    const quantities = { ...useTreatsStep().data.products.treats }

    // Iterate over the quantities, removing the treats with zero quantities
    for (const id in quantities) {
      if (quantities[id] === 0) {
        delete quantities[id]
      }
    }

    return quantities
  })

  const totalTreatQuantity = computed(() => {
    return Object.values(treatQuantities.value).reduce((sum, curr) => sum + curr, 0)
  })

  const mealPlan = computed<MealPlan | undefined>(() => {
    return mealPlanStore.mealPlans.find(({ type }) => type === mealPlanType)
  })

  const mealPlanFrequency = computed(() => {
    return mealPlan.value?.frequency
  })

  const mealWeights = computed(() => {
    return mealPlanStore.mealWeights
  })

  const mealPlanPrice = computed<number>(() => {
    return mealPlan.value?.totalPrice ?? 0
  })

  const totalTreatsPrice = computed<number>(() => {
    return getTotalTreatsPrice(treatsStore.products, treatQuantities.value)
  })

  const totalPrice = computed<number>(() => {
    return totalTreatsPrice.value + mealPlanPrice.value
  })

  const treatsInEveryBox = computed(() => {
    return useTreatsStep().data.products.everyBox
  })

  const discountedTotalPrice = computed<number>(() => {
    return (
      getDiscountedPrice('meals', mealPlanPrice.value, mealPlan.value?.type) +
      getDiscountedPrice('treats', totalTreatsPrice.value)
    )
  })

  const totalDiscount = computed<number>(() => {
    const discountPerBox = totalPrice.value - discountedTotalPrice.value

    return discountPerBox
  })

  const submitOrder = async (
    stripeToken: StripePaymentToken,
    password: string,
    signupPaymentMethod: PaymentMethod,
    paymentIntentId?: string,
  ): Promise<OrderCheckoutResult> => {
    const valid = useCheckoutStep().valid

    if (!valid || !mealPlan.value || !mealPlanType || fatalError.value) {
      return {
        status: 'error',
      }
    }

    error.value = undefined

    const recaptchaToken = await useRecaptcha().getToken()

    const inDeferredTrialDiscountExperiment = useExperiments().inVariant(
      'known-bab-trial-box-v4',
      'deferred-trial-box-discount',
    )

    const order = useCheckoutOrder({
      stripeToken,
      stripePaymentIntent: paymentIntentId,
      signupPaymentMethod,
      password,
      totalPrice: discountedTotalPrice.value,
      coupon: appliedCoupon.value,
      referrer: referrer.value,
      mealPlan: mealPlan.value,
      mealWeights: mealWeights.value,
      recaptchaToken,
      deferredTrialDiscount: inDeferredTrialDiscountExperiment,
    })

    const result = await order.submitOrder()

    if (result.status === 402) {
      useSentry().log('Additional payment confirmation required')

      return {
        status: 'pending_verification',
        data: result.data as PendingPurchaseConfirmationResponse,
      }
    }

    if (result.error) {
      useSentry().error(result)

      // If the server responds with certain status codes set the fatal error to prevent subsequent checkout attempts
      if (result.status && FATAL_CHECKOUT_STATUS_CODES.includes(result.status)) {
        fatalError.value = true
      }

      error.value = result.error

      return {
        status: 'error',
      }
    } else {
      useSentry().log('Checkout success')
    }

    return {
      status: result.success ? 'completed' : 'error',
    }
  }

  const applyCoupon = (coupon: Coupon | undefined): void => {
    setCoupon(coupon)
  }

  const removeCoupon = (): void => {
    removeActiveCoupon()
  }

  const createSetupIntent = async (): Promise<SetupIntent> => {
    const { status, data } = await useApi().post<SetupIntent>('checkout/createSetupIntent', {})

    if (status !== 200) {
      throw new Error('There was an error setting up payment')
    }

    return data
  }

  const generateStripeToken = async (stripeElements: StripeElements): Promise<StripePaymentToken> => {
    // payment intent flow
    if (discountedTotalPrice.value > 0) {
      return await useStripe().handleConfirmationToken(stripeElements)
    }

    // setup intent flow - create setup intent
    const setupIntent = await createSetupIntent()
    await useStripe().handleSetupIntent(stripeElements, setupIntent.clientSecret)
    return {
      setupIntent: setupIntent.id,
    }
  }

  /**
   * Complete payment intent purchase flows
   */
  const completePurchase = async (
    stripeElements: StripeElements,
    password: string,
    paymentMethod: PaymentMethod,
  ): Promise<boolean> => {
    let stripeToken: StripePaymentToken | null

    try {
      stripeToken = await generateStripeToken(stripeElements)
    } catch (err) {
      if (err instanceof Error) {
        error.value = err.message
      }

      return false
    }

    let checkoutResult = await submitOrder(stripeToken, password, paymentMethod)
    if (checkoutResult.status === 'completed') {
      useSentry().log('Completed checkout using payment intent')

      return true
    }

    if (checkoutResult.status === 'error') {
      return false
    }

    if (checkoutResult.status === 'pending_verification') {
      let validated = false

      try {
        validated = await useStripe().handlePaymentIntentValidation(checkoutResult.data!.clientSecretId)
      } catch (err) {
        if (err instanceof Error) {
          error.value = err.message
        }

        return false
      }

      if (validated) {
        checkoutResult = await submitOrder(stripeToken, password, paymentMethod, checkoutResult.data!.paymentIntentId)

        if (checkoutResult.status !== 'completed') {
          error.value = 'We were unable to complete your purchase.'
          useSentry().log(`Error completing checkout after verifying payment intent`)
          return false
        }

        return true
      }

      return false
    }

    error.value = 'An unexpected error occurred completing your purchase'
    return false
  }

  return {
    mealPlan,
    mealPlanPrice,
    mealPlanFrequency,
    mealWeights,
    treatQuantities,
    treatsInEveryBox,
    totalTreatsPrice,
    totalTreatQuantity,
    totalPrice,
    discountedTotalPrice,
    totalDiscount,
    error,
    fatalError,
    appliedCoupon,
    submitting,
    discount,
    submitOrder,
    completePurchase,
    applyCoupon,
    removeCoupon,
  }
}

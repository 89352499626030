export enum AllergenName {
  Egg = 'egg',
  Lamb = 'lamb',
  Chicken = 'chicken',
  Beef = 'beef',
  Fish = 'fish',
  Turkey = 'turkey',
  FishOil = 'fish_oil',
  Kangaroo = 'kangaroo',
  Goat = 'goat',
  Unknown = 'unknown',
}

export interface Allergy {
  id: number
  name: AllergenName
  active: 0 | 1
  allergyDescription?: string
  displayOrder: number
  displayText: string
}

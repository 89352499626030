<script setup lang="ts">
import { computed } from 'vue'
import { useProductPrice } from '@/composables/useProductPrice'
import { type MealPlan, isTrialPlanType } from '@/models/MealPlan'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const { isProductDiscounted, getDiscountedPrice } = useProductPrice()

const totalPrice = computed<number>(() => {
  return props.mealPlan.totalPrice
})

const discounted = computed<boolean>(() => {
  return isProductDiscounted('meals', props.mealPlan.type)
})

const discountedTotalPrice = computed<number>(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice, props.mealPlan.type)
})

const dailyPrice = computed<number>(() => {
  return props.mealPlan.priceDaily
})

const discountedDailyPrice = computed<number>(() => {
  return getDiscountedPrice('meals', props.mealPlan.priceDaily, props.mealPlan.type)
})

const isTrialPlan = computed<boolean>(() => {
  return isTrialPlanType(props.mealPlan.type)
})

const showPricePerDay = computed<boolean>(() => {
  if (isTrialPlan.value) {
    return false
  }

  return true
})

const discountedPrice = computed<string>(() => {
  if (isTrialPlan.value) {
    return formatMoney(discountedTotalPrice.value, false)
  }

  return formatMoney(discountedDailyPrice.value, false)
})

const price = computed<string | undefined>(() => {
  if (discounted.value === false) {
    return
  }

  if (isTrialPlan.value) {
    return formatMoney(totalPrice.value, false)
  }

  return formatMoney(dailyPrice.value, false)
})
</script>

<template>
  <div class="tw-text-right tw-flex tw-flex-col tw-min-h-full">
    <s v-if="discounted" class="tw-block tw-text-semidark-gray">{{ price }}<span v-if="showPricePerDay">/day</span></s>
    <span class="tw-block">
      <span class="tw-text-xl tw-font-bold sm:tw-text-2xl md:tw-text-4xl">{{ discountedPrice }}</span>
      <span v-if="showPricePerDay">/day</span>
    </span>
  </div>
</template>

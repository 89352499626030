<script setup lang="ts">
import { computed } from 'vue'
import PlanStarterExperimentCardPrice from './PlanStarterExperimentCardPrice.vue'
import PlanStarterExperimentCardImage from './PlanStarterExperimentCardImage.vue'
import { type MealPlan, MealPlanType, getTrialPlanContents, isTrialPlanType } from '@/models/MealPlan'
import QuestionMarkFilledIcon from '@/components/icons/QuestionMarkFilledIcon.vue'
import { useProductPrice } from '@/composables/useProductPrice'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const emits = defineEmits<{
  (e: 'select', plan: MealPlan): void
  (e: 'info', plan: MealPlan): void
}>()

const { getDiscountPercent, isMealTypeDiscounted } = useProductPrice()

const title = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
    case MealPlanType.Starter:
    case MealPlanType.Starter1Week:
      return 'Full Bowl'
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return 'Half Bowl'
    case MealPlanType.Trial:
    case MealPlanType.Trial7x200g:
    case MealPlanType.Trial14x200g:
      return 'Trial Box'
    default:
      return props.mealPlan.type satisfies never
  }
})

const description = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return 'Complete and balanced diet'
    case MealPlanType.Half:
      return 'Mix Lyka with other food'
    case MealPlanType.Starter:
      return '14 days of Lyka meals for a complete diet'
    case MealPlanType.StarterHalf:
      return `14 days of Lyka meals to mix with other foods`
    case MealPlanType.Starter1Week:
      return '7 days of Lyka meals for a complete diet'
    case MealPlanType.StarterHalf1Week:
      return `7 days of Lyka meals to mix with other foods`
    case MealPlanType.Trial:
    case MealPlanType.Trial7x200g:
    case MealPlanType.Trial14x200g:
      return 'Start with a small amount of Lyka to try over 2 weeks'
    default:
      return props.mealPlan.type satisfies never
  }
})

const isOversizedStarter = computed<boolean>(() => {
  return props.mealPlan.type === MealPlanType.Starter && props.mealPlan.frequency === 1
})

const percentOfCalories = computed<string | undefined>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
    case MealPlanType.Starter1Week:
      return '100%'
    case MealPlanType.Half:
    case MealPlanType.StarterHalf:
    case MealPlanType.StarterHalf1Week:
      return '50%'
    case MealPlanType.Starter:
      return isOversizedStarter.value ? '50%' : '100%'
    default:
      return undefined
  }
})

const discountPercent = computed<number>(() => {
  return getDiscountPercent('meals', props.mealPlan.type)
})

const discounted = computed<boolean>(() => {
  return isMealTypeDiscounted(props.mealPlan.type)
})

const features = [
  'Cancel future orders anytime',
  'Control your delivery date & window',
  'Easily edit your meal plan online',
] as const

const isTrialPlan = computed<boolean>(() => {
  return isTrialPlanType(props.mealPlan.type)
})

const showInfoDialog = (): void => {
  emits('info', props.mealPlan)
}
</script>

<template>
  <div class="tw-bg-mint-green tw-rounded-3xl tw-text-left tw-max-w-xl tw-mx-auto tw-relative tw-z-[2]">
    <div
      class="tw-flex tw-place-content-between tw-p-5 tw-relative tw-pl-20 md:tw-p-8 md:tw-pl-32 lg:tw-pl-40 tw-gap-2"
    >
      <PlanStarterExperimentCardImage :meal-plan="mealPlan" />
      <div class="tw-flex-1 tw-grid tw-grid-cols-2 tw-gap-2">
        <h3 class="tw-font-stylized tw-text-3xl sm:tw-text-3xl md:tw-text-5xl lg:tw-text-7xl">
          {{ title }}
        </h3>

        <div class="tw-justify-self-end tw-self-center">
          <div
            v-if="discounted"
            class="tw-bg-green tw-text-white tw-rounded-md tw-px-3 tw-py-1 tw-text-center tw-inline-block"
          >
            {{ discountPercent }}% off
          </div>
        </div>

        <p class="tw-text-sm md:tw-text-base tw-col-span-2 tw-mb-2">{{ description }}</p>

        <div class="tw-self-end">
          <button
            type="button"
            class="tw-select-none tw-text-sm tw-whitespace-nowrap focus:tw-outline-none hover:tw-text-green-dark tw-inline-flex tw-gap-2 tw-py-2 tw-px-3 tw-items-center tw-text-green-dark tw-font-bold hover:tw-underline tw-bg-[#F5FAF4] tw-rounded-xl"
            @click.prevent.stop="showInfoDialog"
          >
            <span v-if="isTrialPlan" class="tw-font-bold"
              >{{ getTrialPlanContents(mealPlan.type) }} taster pouches</span
            >
            <span v-else>{{ percentOfCalories }} Lyka</span>
            <QuestionMarkFilledIcon />
          </button>
        </div>

        <div class="tw-self-end">
          <PlanStarterExperimentCardPrice :meal-plan="mealPlan" />
        </div>
      </div>
    </div>
    <div class="tw-border-mint-green-dark tw-border-t tw-text-sm md:tw-text-base tw-p-5 md:tw-p-8 md:tw-px-10">
      <ul class="tw-space-y-2">
        <li v-for="feature in features" :key="feature" class="tw-flex tw-gap-3 tw-items-center">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.77 4.722a.625.625 0 1 0-1.04-.694L8.653 14.644l-4.461-4.46a.625.625 0 1 0-.884.883l5 5a.625.625 0 0 0 .962-.095l7.5-11.25Z"
              fill="#10B193"
            />
          </svg>

          <span>{{ feature }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

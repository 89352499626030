<template>
  <svg
    width="24"
    height="28"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="tw-w-[1em] tw-h-[1em]"
  >
    <path
      d="M11.143 1.143A.86.86 0 0 1 12 .286a.86.86 0 0 1 .857.857v.9a8.568 8.568 0 0 1 7.714 8.528v1.56a8.85 8.85 0 0 0 2.588 6.246l.15.15a2.371 2.371 0 0 1-1.677 4.05L2.373 22.57a2.371 2.371 0 0 1-1.677-4.05l.15-.15a8.845 8.845 0 0 0 2.583-6.24v-1.56c0-4.446 3.38-8.1 7.714-8.528v-.9ZM12 3.714a6.857 6.857 0 0 0-6.857 6.857v1.56c0 2.796-1.11 5.48-3.091 7.456l-.145.145a.659.659 0 0 0 .466 1.125h19.254a.658.658 0 0 0 .466-1.125l-.15-.15a10.551 10.551 0 0 1-3.091-7.457v-1.559a6.857 6.857 0 0 0-6.857-6.857l.005.005ZM10.382 24.86a1.72 1.72 0 0 0 3.236 0 .86.86 0 0 1 1.093-.525.86.86 0 0 1 .525 1.093 3.43 3.43 0 0 1-6.472 0 .856.856 0 0 1 .525-1.093.854.854 0 0 1 1.093.525Z"
      fill="#10B193"
    />
  </svg>
</template>

import type { Coupon } from './Coupon'
import type { Address } from './Address'
import type { DeliveryDetails } from './DeliveryDetails'
import type { CompletedDog } from './Dog'
import type { MealPlanWeight } from './MealPlanWeight'
import type { MealPlan } from './MealPlan'
import type User from './User'
import { sha1 } from '@/utils/sha1'

export const OTHER_OPTION_NAME = 'Other'
export const BREEDER_OPTION_NAME = 'Breeders'
export const VET_OPTION_NAME = 'Vet'
export const DOG_TRAINER_OPTION_NAME = 'Dog Trainer'

export interface HowDidYouHear {
  option: number | null
  name: string
  other?: string
}

export class CheckoutOrder {
  anonymousUserId?: string
  recaptchaToken?: string
  deferredTrialDiscount = false
  stripeToken?: string
  stripeConfirmationToken?: string
  stripeSetupIntent?: string
  signupPaymentMethod?: string
  stripePaymentIntent?: string
  password?: string
  user?: User
  coupon?: Coupon
  referrer?: string
  address?: Address
  deliveryDetails?: DeliveryDetails
  mealPlan?: MealPlan
  dogs: CompletedDog[] = []
  mealPlanWeights: MealPlanWeight[] = []
  productQuantities: Record<number | string, number> = {}
  productsInEveryBox = false
  howDidYouHear: HowDidYouHear = {
    option: null,
    name: '',
  }
}

export const getOrderId = async (order: CheckoutOrder): Promise<string | undefined> => {
  if (order.user) {
    return sha1(order.user.email)
  }

  return undefined
}

<script setup lang="ts">
import { LykaButton, LykaCloseIcon, LykaError, LykaInput } from '@lyka/ui'
import { computed, onMounted, ref } from 'vue'
import LykaIcon from '@lyka/ui/components/LykaIcon.vue'
import type { Coupon } from '@/models/Coupon'
import { useCoupons } from '@/composables/useCoupons'
import type { MealPlanType } from '@/models/MealPlan'
import { useCouponAppliedSuccessEvent } from '@/composables/events/segment/useCouponAppliedSuccessEvent'
import { useCouponAppliedFailureEvent } from '@/composables/events/segment/useCouponAppliedFailureEvent'
import { useCouponRemovedEvent } from '@/composables/events/segment/useCouponRemovedEvent'
import { useCheckout } from '@/composables/useCheckout'
import BoxIcon from '@/components/icons/BoxIcon.vue'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  coupon?: Coupon
  email: string
  mealPlanType?: MealPlanType
}>()

const emits = defineEmits<{
  (e: 'change', coupon: Coupon): void
  (e: 'remove'): void
}>()

const loading = ref(false)
const errorMessage = ref()
const dirty = ref(false)

const coupons = useCoupons()

const { mealPlanPrice, totalDiscount } = useCheckout()

const initialCoupon = computed(() => {
  // default to dollar discount coupon if it was attempted to be used
  if (coupons.dollarDiscountCouponAttempt.value) {
    const dollarDiscountCoupon = coupons.fetchCouponFromURL()

    if (dollarDiscountCoupon) {
      return dollarDiscountCoupon
    }
  }

  return props.coupon?.couponCode ?? ''
})

const code = ref(initialCoupon.value)

const onInput = (): void => {
  dirty.value = true
}

const disabled = computed(() => {
  return !code.value.length || loading.value
})

const removeCoupon = (): void => {
  code.value = ''
  emits('remove')
}

const removeDollarDiscountCoupon = (): void => {
  if (!coupons.dollarDiscountCouponAttempt.value) {
    return
  }

  if (code.value !== initialCoupon.value) {
    return
  }

  // remove coupon if dollar discount
  emits('remove')
  code.value = initialCoupon.value
}

const handleRemoveCoupon = (): void => {
  if (props.coupon) {
    useCouponRemovedEvent(props.coupon).send()
  }

  removeCoupon()
}

const applyCoupon = (coupon: Coupon): void => {
  emits('change', coupon)
}

const validateCoupon = async (): Promise<void> => {
  if (!dirty.value) {
    return
  }

  errorMessage.value = undefined
  dirty.value = false

  if (props.coupon) {
    useCouponRemovedEvent(props.coupon).send()
  }

  if (!code.value) {
    removeCoupon()
    return
  }

  try {
    loading.value = true
    const { coupon, success, error } = await useCoupons().verifyCoupon(
      code.value,
      props.email,
      props.mealPlanType,
      mealPlanPrice.value,
    )

    if (success && coupon) {
      useCouponAppliedSuccessEvent(coupon).send()
      applyCoupon(coupon)
    } else if (error) {
      useCouponAppliedFailureEvent(code.value).send()

      errorMessage.value = error

      removeDollarDiscountCoupon()
    } else {
      removeCoupon()
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  if (coupons.dollarDiscountCouponAttempt.value) {
    dirty.value = true
    await validateCoupon()
  }
})
</script>

<template>
  <div>
    <span class="tw-font-bold"><slot name="title" /></span>
    <div v-if="!coupon">
      <LykaInput
        v-model="code"
        name="coupon"
        placeholder="Referral or promo code"
        label="Referral or promo code"
        hide-label
        :disabled="loading"
        @change="validateCoupon"
        @input="onInput"
      >
        <template #suffix>
          <div class="tw-flex tw-gap-2">
            <button
              v-if="coupon"
              id="checkout-remove-coupon"
              type="button"
              title="Remove coupon"
              @click="handleRemoveCoupon"
            >
              <LykaCloseIcon />
            </button>
            <LykaButton
              type="button"
              :disabled="disabled"
              outline
              class="tw--mr-5"
              :loading="loading"
              @click="validateCoupon"
            >
              {{ coupon ? 'Applied' : 'Apply' }}
            </LykaButton>
          </div>
        </template>
      </LykaInput>

      <LykaError v-if="errorMessage">
        {{ errorMessage }}
      </LykaError>
    </div>

    <div v-if="coupon" class="tw-flex tw-justify-between tw-pt-6 tw-gap-4">
      <div class="tw-flex tw-space-x-2">
        <LykaIcon name="Tick" class="tw-text-lg tw-text-green" />
        <div class="tw-flex tw-flex-col tw-space-y-1">
          <span>
            <strong>{{ coupon.couponCode }}</strong> Applied
          </span>
          <span v-if="totalDiscount > 0" class="tw-font-light tw-text-sm">
            Save {{ Math.abs(totalDiscount) > 0 ? formatMoney(totalDiscount) : 0 }} on this order
          </span>
        </div>
      </div>
      <LykaButton id="checkout-remove-coupon" type="button" variant="success" text-link @click="handleRemoveCoupon">
        Remove
      </LykaButton>
    </div>

    <div
      v-if="coupon?.multiBoxDiscountEnabled"
      class="tw-mt-6 tw-justify-center tw-bg-mint-green tw-text-sm tw-rounded-t-lg tw-py-2 tw-text-center tw-px-4 tw-border tw-border-mint-green-dark"
    >
      <strong>Save {{ formatMoney(totalDiscount) }}</strong> over your first
      {{ coupon?.multiBoxDiscounts?.length }} deliveries!
    </div>

    <div v-if="coupon?.multiBoxDiscountEnabled" class="tw-border tw-border-mint-green-dark tw-rounded-b-lg tw-p-2">
      <div
        v-for="discountItem in coupon?.multiBoxDiscounts"
        :key="discountItem.box"
        class="tw-flex tw-flex-column tw-flex-wrap tw-rounded-lg tw-mb-1 tw-px-6 tw-py-2 tw-border-t-0"
      >
        <div class="tw-flex tw-flex-row tw-gap-x-4">
          <BoxIcon />
          <div class="tw-font-bold">Delivery {{ discountItem.box }}</div>
        </div>
        <div class="tw-ml-auto tw-text-green tw-font-bold">{{ discountItem.discount }}% OFF</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { capitalize, computed, onMounted, reactive, ref, watch } from 'vue'
import { clone } from 'remeda'
import { LykaContainer } from '@lyka/ui'
import TrustpilotReviews from '../TrustpilotReviews.vue'
import MiniHr from '../common/MiniHr.vue'
import AnyQuestion from '../common/AnyQuestion.vue'
import StepNavigation from '../StepNavigation.vue'
import TickIcon from '../icons/TickIcon.vue'
import PlanFirstBox from './plan/PlanFirstBox.vue'
import PlanOptions from './plan/PlanOptions.vue'
import PlanStepLoading from './plan/PlanStepLoading.vue'
import { useNoCommonRecipesEvent } from '@/composables/events/segment/useNoCommonRecipes'
import { type MealPlan, MealPlanType, getMealPlanName } from '@/models/MealPlan'
import type { PlanStepData } from '@/steps/plan'
import { AcquisitionPage, useAcquisitionData } from '@/composables/useAcquisitionData'
import { useMealPlansStore } from '@/stores/mealPlans'
import { usePlansCalculatedEvent } from '@/composables/events/segment/usePlansCalculatedEvent'
import { useRecipesStore } from '@/stores/recipes'
import type { Recipe } from '@/models/Recipe'
import { isTherapeuticRecipe } from '@/models/Recipe'
import PlanStarteExperimentOptions from '@/components/steps/plan/experiments/PlanStarterExperimentOptions.vue'
import { useStarterHalfExperiment } from '@/composables/experiments/useStarterHalfExperiment'

const props = defineProps<{
  stepData: PlanStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const mealPlanStore = useMealPlansStore()
const recipesStore = useRecipesStore()

const data = reactive(clone(props.stepData))
const loading = ref(true)
const starterPlanDuration = ref<number>(2)

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const starterHalfExperiment = useStarterHalfExperiment()

const hasTherapeuticRecipes = computed<boolean>(() => {
  return recipesStore.selectedRecipes.some(isTherapeuticRecipe)
})

const visibleMealPlans = computed<MealPlan[]>(() => {
  if (starterHalfExperiment.enabled.value) {
    // For plans with a duration of 1 week, show only 1 week plans
    if (starterPlanDuration.value === 1) {
      return mealPlanStore.getMealPlansByType(MealPlanType.Starter1Week, MealPlanType.StarterHalf1Week)
    }

    if (starterHalfExperiment.eligibleForHalfStarter.value) {
      return mealPlanStore.getMealPlansByType(MealPlanType.Starter, MealPlanType.StarterHalf)
    }

    // If the user is not eligible for the half starter plan, show only the full starter plan
    return mealPlanStore.getMealPlansByType(MealPlanType.Starter)
  }

  // Otherwise show the default plans
  return mealPlanStore.getMealPlansByType(MealPlanType.Full, MealPlanType.Half, MealPlanType.Starter)
})

const nextButtonLabel = computed(() => {
  const { type } = props.stepData.plan

  if (visibleMealPlans.value.length === 1) {
    return 'Continue'
  }

  // Show different button label for
  if (starterHalfExperiment.enabled.value) {
    switch (type) {
      case MealPlanType.Starter:
      case MealPlanType.Starter1Week:
        return 'Choose full bowl'
      case MealPlanType.StarterHalf:
      case MealPlanType.StarterHalf1Week:
        return 'Choose half bowl'
    }
  }

  if (type) {
    const name = capitalize(getMealPlanName(type).replace(' plan', ''))

    return `Choose ${name}`
  }

  return 'Select a plan'
})

const hasCommonDogRecipes = computed<boolean>(() => {
  const [firstDog, secondDog] = recipesStore.selectedRecipesByDog

  if (firstDog === undefined) {
    return false
  }

  if (secondDog === undefined) {
    return true
  }

  return firstDog.some((recipe) => secondDog.includes(recipe))
})

const recipes = computed<Recipe[]>(() => recipesStore.selectedRecipes)

const onPlanSelected = async (plan: MealPlan | undefined, submit = true): Promise<void> => {
  data.plan.type = plan?.type ?? null

  if (submit) {
    emits('submit', data)
  } else {
    emits('save', data)
  }
}

// Auto-select the first plan in the list of visible plans
const autoSelectDefaultPlan = (): void => {
  const [first] = visibleMealPlans.value

  if (first) {
    data.plan.type = first.type
    emits('save', data)
  }
}

const loadPlans = async (): Promise<void> => {
  const { mealPlans, mealWeights } = await mealPlanStore.loadMeals()

  usePlansCalculatedEvent({ mealPlans, mealWeights, visibleMealPlans: visibleMealPlans.value }).send()
}

onMounted(async () => {
  try {
    await loadPlans()

    starterPlanDuration.value = await mealPlanStore.getStarterPlanDuration()

    // If the currently selected plan is not visible, auto-select the first of the visible plans
    const isVisiblePlan = visibleMealPlans.value.some((plan) => data.plan.type === plan.type)

    if (!isVisiblePlan) {
      autoSelectDefaultPlan()
    }
  } finally {
    loading.value = false

    useAcquisitionData().send(AcquisitionPage.Plan)

    if (!hasCommonDogRecipes.value) {
      useNoCommonRecipesEvent().send()
    }
  }
})
</script>

<template>
  <div ref="stepNav">
    <PlanStepLoading v-if="loading" />
    <div v-else class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <template v-if="starterHalfExperiment.enabled.value">
            <PlanStarteExperimentOptions
              :selected-meal-plan-type="data.plan.type"
              :meal-plans="visibleMealPlans"
              :meal-weights="mealPlanStore.mealWeights"
              :therapeutic="hasTherapeuticRecipes"
              @select="(mealPlan) => onPlanSelected(mealPlan, false)"
            />
          </template>
          <template v-else>
            <PlanOptions
              :selected-meal-plan-type="data.plan.type"
              :meal-plans="visibleMealPlans"
              :meal-weights="mealPlanStore.mealWeights"
              :therapeutic="hasTherapeuticRecipes"
              @select="(mealPlan) => onPlanSelected(mealPlan, false)"
            />

            <div class="tw-space-y-8">
              <h3 class="tw-h2">Feeding with Lyka is flexible</h3>
              <ul class="tw-space-y-1 tw-text-base sm:tw-text-lg md:tw-text-xl tw-w-max tw-mx-auto">
                <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Cancel anytime, hassle-free</li>
                <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Control your delivery time</li>
                <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Simple swaps and changes</li>
              </ul>
            </div></template
          >
        </div>
      </LykaContainer>

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox :meal-plan-type="data.plan.type" :recipes="recipes" />

          <div>
            <LykaContainer size="md" class="tw-space-y-4 tw-mb-8">
              <h2 class="tw-h2 tw-mb-6"><b class="tw-text-green tw-block">Real changes.</b> Real dogs.</h2>

              <p class="tw-lead">
                Taking pride of place in dog bowls around the country, discover how Lyka is changing the game and
                improving lives.
              </p>
            </LykaContainer>

            <TrustpilotReviews />
          </div>

          <div class="tw-space-y-2 sm:tw-space-y-6">
            <MiniHr />

            <AnyQuestion center />
          </div>
        </div>
      </LykaContainer>
    </div>
  </div>

  <StepNavigation
    :next-text="nextButtonLabel"
    :next-disabled="!valid"
    @previous="emits('previous')"
    @next="emits('submit', data)"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductPrice } from '@/composables/useProductPrice'
import { type MealPlan, isTrialPlanType } from '@/models/MealPlan'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const { isProductDiscounted, getDiscountedPrice } = useProductPrice()

const totalPrice = computed<number>(() => {
  return props.mealPlan.totalPrice
})

const discounted = computed<boolean>(() => {
  return isProductDiscounted('meals', props.mealPlan.type)
})

const discountedTotalPrice = computed<number>(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice, props.mealPlan.type)
})

const discountedDailyPrice = computed<number>(() => {
  return discountedTotalPrice.value / (props.mealPlan.frequency * 7)
})

const isTrialPlan = computed<boolean>(() => {
  return isTrialPlanType(props.mealPlan.type)
})

const primaryPrice = computed<string>(() => {
  if (isTrialPlan.value) {
    return `${formatMoney(discountedTotalPrice.value)} total`
  }

  return `${formatMoney(discountedDailyPrice.value)}/day`
})

const secondaryPrice = computed<string | undefined>(() => {
  if (isTrialPlan.value) {
    if (discounted.value) {
      return `Was ${formatMoney(totalPrice.value)}`
    }

    return undefined
  }

  // Show the non-discounted total price if the product is discounted
  if (discounted.value) {
    return `<s>${formatMoney(totalPrice.value)}</s> ${formatMoney(discountedTotalPrice.value)} total`
  }

  return `${formatMoney(discountedTotalPrice.value)} total`
})
</script>

<template>
  <div class="price">
    <div class="price-primary" v-html="primaryPrice"></div>
    <div v-if="secondaryPrice" v-html="secondaryPrice"></div>
  </div>
</template>

<style>
.price {
  @apply tw-flex tw-justify-between tw-items-center tw-gap-2 tw-whitespace-nowrap;
  @apply md:tw-flex-col;
}

.price-primary {
  @apply tw-text-lg tw-font-stylized tw-leading-tighter tw-align-baseline;
  @apply sm:tw-text-3xl;
  @apply lg:tw-text-5xl;
}

.price-secondary {
  @apply tw-text-sm;
}
</style>

import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { SegmentEvents } from '@lyka/vue-common/events/segment'
import type { EventSender } from '../EventSender'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { useStarterHalfExperiment } from '@/composables/experiments/useStarterHalfExperiment'

interface PlanData {
  dailyGrams: number
  frequency: number
  total: number
  eligible: boolean
  shown: boolean
}

const getPlanData = (
  mealPlanType: MealPlanType,
  {
    mealPlans,
    visibleMealPlans,
    dailyGrams,
    eligible = true,
  }: { mealPlans: MealPlan[]; visibleMealPlans: MealPlan[]; dailyGrams: number; eligible?: boolean },
): PlanData | null => {
  const mealPlan = mealPlans.find((plan) => plan.type === mealPlanType)
  const shown = visibleMealPlans.some((plan) => plan.type === mealPlanType)

  if (mealPlan === undefined) {
    return null
  }

  if (mealPlan.type === 'half') {
    dailyGrams /= 2
  }

  return {
    frequency: mealPlan.frequency,
    total: mealPlan.totalPrice,
    dailyGrams,
    eligible,
    shown,
  }
}

export const usePlansCalculatedEvent: EventSender = ({
  mealPlans,
  mealWeights,
  visibleMealPlans,
}: {
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
  visibleMealPlans: MealPlan[]
}) => {
  const experiment = useStarterHalfExperiment()

  const data = (): SegmentEvents['Plans Calculated'] => {
    const dailyGrams = mealWeights.map(({ servingWeight }) => servingWeight).reduce((total, curr) => total + curr, 0)

    return {
      full: getPlanData(MealPlanType.Full, { mealPlans, visibleMealPlans, dailyGrams }),
      half: getPlanData(MealPlanType.Half, { mealPlans, visibleMealPlans, dailyGrams }),
      starter: getPlanData(MealPlanType.Starter, { mealPlans, visibleMealPlans, dailyGrams }),
      starterHalf: getPlanData(MealPlanType.StarterHalf, {
        mealPlans,
        visibleMealPlans,
        dailyGrams,
        eligible: experiment.eligible.value,
      }),
    }
  }

  const send = (): void => {
    useSegment().track('Plans Calculated', data())
  }

  return {
    send,
    data,
  }
}

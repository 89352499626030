import { computed } from 'vue'
import { useCoupons } from './useCoupons'
import {
  type Coupon,
  type DiscountProductType,
  getDiscountedPrice as getDiscountedCouponPrice,
  isProductTypeDiscounted,
} from '@/models/Coupon'
import { type MealPlanType } from '@/models/MealPlan'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useProductPrice = () => {
  const coupons = useCoupons()

  const coupon = computed<Coupon | undefined>(() => coupons.activeCoupon.value)

  const discountType = computed<DiscountProductType | undefined>(() => {
    return coupon.value?.discountType
  })

  const discount = computed<number>(() => {
    return coupon.value?.discount ?? 0
  })

  const isMealTypeDiscounted = (_: MealPlanType): boolean => {
    const discounted = isProductTypeDiscounted('meals', coupon.value)

    if (!discounted) {
      return false
    }

    // All meals are currently discounted. This can change so leaving this logic in.
    return true
  }

  const isProductDiscounted = (productType: DiscountProductType, mealType?: MealPlanType): boolean => {
    if (productType === 'meals' && mealType) {
      return isMealTypeDiscounted(mealType)
    }

    return isProductTypeDiscounted(productType, coupon.value)
  }

  const getDiscountPercent = (productType: 'all' | 'meals' | 'treats', mealType?: MealPlanType): number => {
    if (isProductDiscounted(productType, mealType)) {
      return discount.value
    }

    return 0
  }

  const getDiscountedPrice = (productType: 'meals' | 'treats', price: number, mealType?: MealPlanType): number => {
    if (isProductDiscounted(productType, mealType)) {
      return getDiscountedCouponPrice(price, coupon.value)
    }

    return price
  }

  return {
    discount,
    getDiscountedPrice,
    getDiscountPercent,
    isProductDiscounted,
    isMealTypeDiscounted,
    discountType,
  }
}

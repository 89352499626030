<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { createElementId } from '../utils/createElementId'
import { useStringSearch } from '../composables/useStringSearch'
import InputError from './InputError.vue'
import TransitionSlideIn from './transition/SlideIn.vue'
import IconSelectDown from './icon/SelectDown.vue'

type ModelValue = string | number | null | undefined

interface Option {
  text: string
  value: ModelValue
}

const props = withDefaults(
  defineProps<{
    id?: string
    label?: string
    hideLabel?: boolean
    error?: string
    name: string
    size?: 'sm' | 'md' | 'lg'
    modelValue: ModelValue
    options: Option[]
    disabled?: boolean
    required?: boolean
    showOptional?: boolean
    search?: boolean
    placeholder?: string
  }>(),
  {
    size: 'md',
  },
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
  (e: 'change', value: ModelValue): void
}>()

const select = ref<HTMLInputElement>()
const element = ref<HTMLElement>()
const searchData = ref<string>()
const show = ref(false)
const focusedIndex = ref(-1)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
    emits('change', value)
  },
})

const inputId = props.id ? props.id : createElementId()

const inputHeight = computed(() => {
  switch (props.size) {
    case 'sm': {
      return 'tw-global-height--small'
    }
    case 'lg': {
      return 'tw-global-height--large'
    }
    default: {
      return 'tw-global-height'
    }
  }
})

const filteredOptions = computed(() => {
  if (!searchData.value) {
    return props.options
  }

  const stringSearch = useStringSearch(props.options)
  const searchValue = searchData.value || ''
  return stringSearch.search(searchValue).map((option) => {
    const searchIdx = option.text.toLowerCase().indexOf(searchValue.toLowerCase())
    const highlightedText = option.text.substring(searchIdx, searchIdx + searchValue.length)
    return {
      ...option,
      text:
        searchValue !== ''
          ? option.text.replace(highlightedText, `<span class="tw-highlight-text">${highlightedText}</span>`)
          : option.text,
    }
  })
})

const isSelected = (index: number): boolean => {
  return index === focusedIndex.value
}

const isFocused = (index: number): string => {
  if (index === focusedIndex.value) {
    return 'tw-select-dropdown-item--active'
  }

  return ''
}

const showDropdown = (): void => {
  show.value = true
}

const closeDropdown = (): void => {
  show.value = false
}

const toggleDropdown = (): void => {
  show.value = !show.value
}

const findOptionByText = (text: string): Option | undefined => {
  return props.options.find((option) => option.text.toLowerCase() === text.toLowerCase())
}

const checkFocusIndex = (value: ModelValue): void => {
  focusedIndex.value = props.options.findIndex((option) => option.value?.toString() === value?.toString())
}

watch(
  () => props.modelValue,
  (newValue) => {
    checkFocusIndex(newValue)

    searchData.value = undefined
  },
  { immediate: true },
)

const selectItem = (newValue: ModelValue): void => {
  value.value = newValue
  closeDropdown()
}

const closeAndResetFocus = (): void => {
  show.value = false
  checkFocusIndex(props.modelValue)
}

const changeIndex = (dir: number): void => {
  focusedIndex.value = (focusedIndex.value + dir + props.options.length) % props.options.length
}

const handleKeyPress = (ev: KeyboardEvent): void => {
  switch (ev.code) {
    case 'Tab':
      closeAndResetFocus()
      break

    case 'Escape':
      if (show.value) {
        ev.preventDefault()
        ev.stopPropagation()
        closeAndResetFocus()
      }
      break

    case 'Enter':
    case 'Space': {
      ev.preventDefault()
      const option = props.options[focusedIndex.value]?.value

      if (option) {
        selectItem(option)
      }

      break
    }

    case 'ArrowUp':
      ev.preventDefault()
      changeIndex(-1)
      break

    case 'ArrowDown':
      ev.preventDefault()
      changeIndex(1)
      break
  }
}

const searchLabel = computed({
  get() {
    if (searchData.value !== undefined) {
      return searchData.value
    }

    return props.options[focusedIndex.value]?.text ?? ''
  },
  set(value: string) {
    searchData.value = value
  },
})

// If clicking outside this component then close it
const onDocumentMousedown = (ev: MouseEvent): void => {
  if (ev.target instanceof Element && !element.value?.contains(ev.target)) {
    closeAndResetFocus()
  }
}

const handleInput = ({ target }: Event): void => {
  showDropdown()

  if (!(target instanceof HTMLInputElement)) {
    return
  }

  const option = findOptionByText(target.value)

  if (option) {
    selectItem(option.value)
  }
}

onMounted(() => {
  document.addEventListener('mousedown', onDocumentMousedown)
})

onUnmounted(() => {
  document.removeEventListener('mousedown', onDocumentMousedown)
})
</script>

<template>
  <div
    ref="element"
    class="tw-flex tw-flex-col tw-w-full tw-space-y-2 tw-select-wrap tw-relative tw-text-left tw-text-base tw-text-alt"
  >
    <div class="tw-flex tw-gap-2 tw-justify-between">
      <label v-if="label" class="tw-select-label" :class="{ 'tw-sr-only': hideLabel }" :for="inputId">{{
        label
      }}</label>
      <span
        v-if="!required && showOptional"
        class="tw-text-dark tw-text-opacity-50 tw-text-sm"
        :class="{ 'tw-sr-only': hideLabel }"
      >
        (optional)
      </span>
    </div>

    <div class="tw-select-row">
      <input
        v-if="search"
        ref="select"
        v-model="searchLabel"
        :placeholder="placeholder"
        v-bind="$attrs"
        :tabindex="disabled ? '-1' : '0'"
        class="tw-select"
        :class="inputHeight"
        spellcheck="false"
        :name="name"
        @input="handleInput"
        @focus="showDropdown"
      />

      <select
        v-else
        :id="inputId"
        ref="select"
        v-model="value"
        class="tw-select"
        :class="inputHeight"
        v-bind="$attrs"
        :tabindex="disabled ? '-1' : '0'"
        :name="name"
        @mousedown.prevent="toggleDropdown"
        @keydown="handleKeyPress"
      >
        <option
          v-for="(item, index) in filteredOptions"
          :key="index"
          :value="item.value"
          :selected="isSelected(index)"
          class="tw-hidden"
        >
          {{ item.text }}
        </option>
      </select>

      <button
        type="button"
        aria-hidden="true"
        class="tw-flex tw-items-center tw-justify-center tw-aspect-square"
        :class="inputHeight"
        @click="toggleDropdown"
      >
        <IconSelectDown class="tw-transform" :class="{ 'tw-rotate-180': show }" />
      </button>
    </div>

    <TransitionSlideIn>
      <div v-if="show" class="tw-select-dropdown" role="listbox">
        <div class="tw-select-dropdown-inner tw-scrollbar-hidden">
          <div class="tw-p-2">
            <div
              v-for="(item, index) in filteredOptions"
              :key="index"
              role="option"
              class="tw-select-dropdown-item tw-cursor-pointer"
              :class="isFocused(index)"
              :title="item.text"
              :data-option-label="item.text"
              @click.stop="selectItem(item.value)"
            >
              <div class="tw-truncate" v-html="item.text" />
            </div>
            <div v-if="!filteredOptions.length" class="tw-select-dropdown-item tw-space-x-2 tw-text-left">
              <div v-if="$slots.noResults">
                <slot name="noResults" />
              </div>
              <div v-else>No results found</div>
            </div>
          </div>
        </div>
      </div>
    </TransitionSlideIn>

    <InputError v-if="error" :error="error" />
  </div>
</template>

<style lang="postcss">
.tw-highlight-text {
  @apply tw-text-green tw-font-bold;
}
.tw-select-label {
  @apply tw-text-left tw-text-alt;
}
.tw-select-row {
  @apply tw-border tw-border-gold tw-rounded-3xl tw-flex tw-bg-white hover:tw-bg-cream focus:tw-bg-light tw-items-center tw-transition;
}
.tw-select-wrap:focus-within .tw-select-row {
  @apply tw-bg-white tw-border-success tw-shadow-[0_0_0_4px_#DBE6DC];
}
.tw-select {
  @apply tw-bg-transparent tw-px-5 tw-w-full tw-outline-none tw-placeholder-gold;
}
</style>

import { reactive } from 'vue'
import { defer } from '@lyka/utils/src/defer'
import type { Experiments } from './useFlagsmith'

type ExperimentVariant = 'experiment' | 'control' | string

type ExperimentName = 'bab-apple-pay' | 'bab-stripe-payment-element' | 'known-bab-free-topper' | 'bab-trial-box-v2'

type ExperimentFlags = Record<string, string>

const experiments = reactive(new Map<string, ExperimentVariant>())

const loaded = defer<void>()

const DEFAULT_EXPERIMENT_VARIANT = 'experiment' as const

export const experimentsFromURL = (url: string): Record<string, string> => {
  const experiments: Record<string, string> = {}

  // Try to parse the URL for experiment flags
  try {
    const params = new URL(url).searchParams

    for (const [k, v] of params) {
      if (k.startsWith('experiment-')) {
        const experimentName = k.replace('experiment-', '')

        experiments[experimentName] = v || 'experiment'
      }
    }
  } catch {}

  return experiments
}

/**
 * Filter the forced experiments to only include the experiments that are active.
 */
export const filterForcedExperiments = (
  forcedExperiments: Experiments,
  activeExperiments: Experiments,
): Experiments => {
  const experiments: Experiments = {}

  for (const experiment in forcedExperiments) {
    if (experiment in activeExperiments) {
      experiments[experiment] = forcedExperiments[experiment]!
    }
  }

  return experiments
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useExperiments = () => {
  const getVariant = (experiment: string): string | undefined => {
    return experiments.get(experiment)
  }

  const inVariant = (experiment: string, variant: string): boolean => {
    return getVariant(experiment) === variant
  }

  const inExperiment = (experiment: ExperimentName): boolean => {
    return inVariant(experiment, DEFAULT_EXPERIMENT_VARIANT)
  }

  const setExperiments = (flags: ExperimentFlags): void => {
    Object.entries(flags).forEach(([name, variant]) => {
      experiments.set(name, variant)
    })

    loaded.resolve()
  }

  const clearExperiments = (): void => {
    experiments.clear()
  }

  return {
    loaded,
    experiments,
    inVariant,
    getVariant,
    inExperiment,
    setExperiments,
    clearExperiments,
  }
}

import { computed, ref } from 'vue'
import { unwrapData, useApi } from './useApi'
import type { DeliveryData } from './useCheckout'
import { useVerifyPostcode } from './useVerifyPostcode'
import type { DeliveryDate } from '@/models/DeliveryDate'
import { type DeliverySlot, DeliverySlotName } from '@/models/DeliverySlot'

const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000
const ONE_WEEK_DAYS = 7
const PRIORITIZED_DELIVERY_SLOT = DeliverySlotName.PM

// Get the number of days between two dates, rounded down.
// The first date should be before the second date otherwise it will return a negative number.
export const getDaysBetweenDates = (from: Date, to: Date): number => {
  return Math.floor((to.getTime() - from.getTime()) / ONE_DAY_MILLISECONDS)
}

// Calculate the minimum number of days between a delivery date and the next charge date for that delivery date.
// We use this to determine if customer will have sufficient time to 'try' Lyka before we charge them again.
export const getMinDaysBetweenDeliveryAndNextCharge = (
  date: Pick<DeliveryDate, 'date' | 'available' | 'slots'>,
): number => {
  let min = Infinity

  // If not available, return Infinity
  if (date.available === false) {
    return min
  }

  const deliveredDate = new Date(date.date)

  // Iterate through the available slots
  for (const slot of date.slots) {
    if (slot.available === false) {
      continue
    }

    const nextChargeDate = new Date(slot.timings.chargeDate)

    // Add 7 days to the charge date to get the next charge date
    nextChargeDate.setDate(nextChargeDate.getDate() + ONE_WEEK_DAYS)

    // Calculate the number of days between the delivery date and the next charge date
    const days = getDaysBetweenDates(deliveredDate, nextChargeDate)

    // If the number of days is less than the current minimum, update the minimum
    if (days < min) {
      min = days
    }
  }

  return min
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDeliveryDates = () => {
  const api = useApi()
  const verifyPostcode = useVerifyPostcode()

  const dates = ref<DeliveryDate[]>([])
  const loading = ref(false)

  const slots = computed<DeliverySlot[]>(() => {
    return dates.value.flatMap((d) => d.slots)
  })

  const availableDeliveryDates = computed<DeliveryDate[]>(() => {
    return dates.value.filter(({ available }) => available)
  })

  const defaultDeliveryDate = computed<DeliveryDate | undefined>(() => {
    const [first] = availableDeliveryDates.value

    return first
  })

  const defaultDeliverySlot = computed<DeliverySlot | undefined>(() => {
    const slots = [...(defaultDeliveryDate.value?.slots ?? [])]

    return slots
      .sort((slot) => {
        // Prioritize PM slots to avoid AM deliveries as customers prefer not to be woken up early.
        if (slot.name === PRIORITIZED_DELIVERY_SLOT) {
          return -1
        }

        return 1
      })
      .find(({ available }) => available)
  })

  const getSlotsForDate = (date: string): DeliverySlot[] => {
    return (
      availableDeliveryDates.value
        .find((deliveryDate) => deliveryDate.date === date)
        ?.slots.filter(({ available }) => available) ?? []
    )
  }

  const checkDeliverySlotValid = ({ date, slot }: Pick<DeliveryData, 'date' | 'slot'>): boolean => {
    // Find the matching date for the delivery
    const dateMatch = availableDeliveryDates.value.find(
      (deliveryDate) => deliveryDate.available && deliveryDate.date === date,
    )

    // Find the slot for the delivery
    const slotMatch = dateMatch?.slots.find((deliverySlot) => deliverySlot.available && deliverySlot.cutoffId === slot)

    return !!(dateMatch && slotMatch)
  }

  const clearDeliveryDates = (): void => {
    dates.value = []
  }

  const loadDeliveryDates = async (postcode: string): Promise<void> => {
    try {
      loading.value = true
      dates.value = []

      // First verify that the postcode is valid. We need to do this as a postcode
      // such as 2004 which is used for post office boxes will return valid delivery
      // slots. We don't delivery to PO Boxes so need to return no results.
      const valid = await verifyPostcode.verify(postcode)

      if (!valid) {
        return
      }

      const [ymd] = new Date().toISOString().split('T')

      const params = {
        postcode,
        weeks: 3,
        ignoreChargeCutoff: 1,
        baseDate: ymd,
      }

      const { data } = await api.post<DeliveryDate[]>('shipping/deliverydates/postcodeonly', params, unwrapData)

      if (Array.isArray(data)) {
        dates.value = data
      }
    } finally {
      loading.value = false
    }
  }

  const getSlotById = (slotId: number): DeliverySlot | undefined => {
    return slots.value.find((s) => s.cutoffId === slotId)
  }

  const getTimeForSlot = (slot: Pick<DeliverySlot, 'name'>): string | undefined => {
    switch (slot.name) {
      case DeliverySlotName.AM:
        return '12am - 7am'
      case DeliverySlotName.PM:
        return '8am - 6pm'
      default:
        return undefined
    }
  }

  const getMinDaysBetweenOrders = (): number => {
    let min = Infinity

    for (const date of dates.value) {
      const days = getMinDaysBetweenDeliveryAndNextCharge(date)

      if (days < min) {
        min = days
      }
    }

    return min
  }

  return {
    defaultDeliveryDate,
    defaultDeliverySlot,
    availableDeliveryDates,
    loading,
    getSlotById,
    getTimeForSlot,
    getSlotsForDate,
    checkDeliverySlotValid,
    clearDeliveryDates,
    loadDeliveryDates,
    getMinDaysBetweenOrders,
  }
}
